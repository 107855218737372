import { removeAccents } from "../../../utils";

export const generarSeleccionMenuEmpleado = (diasSemanas, categorias, menuEmpleadoSemana) => {
    let diaInsert = {};

    for (let i = 0; i < diasSemanas.length; i++) {
        
        let { descripcion, id: diaId } = diasSemanas[i];

        diaInsert = { 
        ...diaInsert, 
        [removeAccents(descripcion)]: {
                
        }
        }

        for (let x = 0; x < categorias.length; x++) {

            const { id, descripcion: nombreCategoria } = categorias[x];

            if(menuEmpleadoSemana.length > 0){

                let seleccionEmpleadoComida;

                seleccionEmpleadoComida = menuEmpleadoSemana?.find(x => x.diaId === diaId && x.comida.categoriaId === id) ?? {};

                if(Object.keys(seleccionEmpleadoComida).length > 0){

                    diaInsert[removeAccents(descripcion)] = {
                        ...diaInsert[removeAccents(descripcion)],
                        [removeAccents(nombreCategoria)]: seleccionEmpleadoComida.comidaId
                    }
                }

                
            }
            else{
                diaInsert[removeAccents(descripcion)] = {
                    ...diaInsert[removeAccents(descripcion)],
                    [removeAccents(nombreCategoria)]: {

                    }
                }
            }
            
        }
        
    }

    return diaInsert;
}

export const generarAlmuerzoMenuListEmpleado = (diasSemanas, categoriasAlmuerzoSemanaActual, usuarioId) => {
    let categoriaAlmuerzoDiaEmpleado = [];

    for (let y = 0; y < diasSemanas.length; y++) {

        const menuAlmuerzoDia = categoriasAlmuerzoSemanaActual?.find(x => x.diaId === diasSemanas[y].id) ?? [];         

        const { categoriaAlmuerzo } = menuAlmuerzoDia ?? [];

        const diaSemanaComida = {
            diaId: diasSemanas[y].id,
            categoriaAlmuerzo: []
        }

        for (let i = 0; i < categoriaAlmuerzo?.length; i++) {

            const arrComida = [];

            const { descripcion, comidas } = categoriaAlmuerzo[i];

            let categoriaAlmuerzoEmpleado = {
                descripcion
            };

            for (let x = 0; x < comidas.length; x++) {

                const { id, descripcion: descripcionComida} = comidas[x];
                
                    var comida = {
                        id: 0,
                        empleadoId: usuarioId,
                        tipoAlmuerzoId: menuAlmuerzoDia.tipoAlmuerzoId,
                        comidaId: id,
                        diaId: menuAlmuerzoDia.diaId,
                        semanaId: menuAlmuerzoDia.semanaId,
                        descripcion: descripcionComida
                    }

                    arrComida.push(comida);
            }

            categoriaAlmuerzoEmpleado = {...categoriaAlmuerzoEmpleado, comidas: arrComida};
            diaSemanaComida.categoriaAlmuerzo.push(categoriaAlmuerzoEmpleado);
        }

        categoriaAlmuerzoDiaEmpleado.push(diaSemanaComida);
    }

    return categoriaAlmuerzoDiaEmpleado;
}

export const generarResumenMenu = (seleccionMenuEmpleado, categoriasAlmuerzoSemanaActual, diasSemanas) => {
    let resumenSemanal = {};

    if (Object.keys(seleccionMenuEmpleado).length > 0) {
        for (let i = 0; i < diasSemanas.length; i++) {

            const { descripcion: dia } = diasSemanas[i];

            const normalizedDay = removeAccents(dia);

            const seleccion = seleccionMenuEmpleado[normalizedDay] ?? {};

            resumenSemanal = {...resumenSemanal, [normalizedDay]: ""}

            for (const [key, value] of Object.entries(seleccion)) {

                // * BUSCANDO EL TIPO DE ALMUERZO
                const comidaSeleccionada = categoriasAlmuerzoSemanaActual
                .flatMap(categoria => categoria.categoriaAlmuerzo || [])
                .flatMap(categoria => categoria.comidas || [])
                .find(comida => comida.id === parseInt(value)) ?? {};

                if (Object.keys(comidaSeleccionada).length > 0) {
                    
                    const { descripcion } = comidaSeleccionada;

                    resumenSemanal[normalizedDay] = resumenSemanal[normalizedDay].trim().length === 0 ? descripcion : resumenSemanal[normalizedDay] + ", " + descripcion;
                }
            }
        }
    }

    return resumenSemanal;
}

export const generarListaSeleccionComidaEmpleadoApi = (menuEmpleadoSemana, seleccionMenuEmpleado, categoriasAlmuerzoSemanaActual, diasSemanas) => {
    let arrDataApi = [];

    // * VALIDACION SI EXISTE UN MENU REGISTRADO POR EL USUARIO ACTUALMENTE EN LA SESION.
    if(menuEmpleadoSemana?.length > 0){
        
        // Iteramos por los días de la semana.
        for (let i = 0; i < diasSemanas.length; i++) {
            const { descripcion: dia, id: diaId } = diasSemanas[i];

            // Normalizamos el día para acceder a la propiedad en el estado seleccionMenuEmpleado.
            const normalizedDay = removeAccents(dia);

            // Si no hay selecciones para ese día, seguimos al siguiente.
            const seleccion = seleccionMenuEmpleado[normalizedDay] ?? {};

                // Iteramos sobre las categorías de almuerzo de ese día.
                for (const [key, comidaId] of Object.entries(seleccion)) {

                    // * BUSCANDO EL TIPO DE ALMUERZO
                    const tipoAlmuerzo = categoriasAlmuerzoSemanaActual.find(x => 
                        x.categoriaAlmuerzo && x.categoriaAlmuerzo.some(categoria => 
                            categoria.comidas.some(y => y.id === parseInt(comidaId))
                        )
                    );

                const menu = menuEmpleadoSemana.find(x => x.diaId === diaId && removeAccents(x.comida.nombreCategoria) === key) ?? {};
                
                    if (tipoAlmuerzo) {
                        arrDataApi.push({
                            id: menu.id ?? 0,  // * SI NO ENCONTRO EL ID SIGNIFICA QUE ES UN REGISTRO NUEVO.
                            comidaId: parseInt(comidaId), 
                            diaId, 
                            empleadoId: 0, 
                            semanaId: categoriasAlmuerzoSemanaActual[0].semanaId, 
                            tipoAlmuerzoId: tipoAlmuerzo.tipoAlmuerzoId
                        });
                    }
                }
            }
    }

    // * ESTE CASO APLICA SI EL MENU ESTA SIENDO REGISTRADO POR PRIMERA VEZ.
    else
    {
        // Iteramos por los días de la semana.
        for (let i = 0; i < diasSemanas.length; i++) {
            const { descripcion: dia, id: diaId } = diasSemanas[i];

            // Normalizamos el día para acceder a la propiedad en el estado seleccionMenuEmpleado.
            const normalizedDay = removeAccents(dia);

            // Si no hay selecciones para ese día, seguimos al siguiente.
            const seleccion = seleccionMenuEmpleado[normalizedDay] ?? {};

                // Iteramos sobre las categorías de almuerzo de ese día.
                for (const [key, comidaId] of Object.entries(seleccion)) {

                    // * BUSCANDO EL TIPO DE ALMUERZO
                    const tipoAlmuerzo = categoriasAlmuerzoSemanaActual.find(x => 
                        x.categoriaAlmuerzo && x.categoriaAlmuerzo.some(categoria => 
                            categoria.comidas.some(y => y.id === parseInt(comidaId))
                        )
                    );
                    
                
                    if (tipoAlmuerzo) {
                        arrDataApi.push({
                            id: 0, 
                            comidaId: parseInt(comidaId), 
                            diaId, 
                            empleadoId: 0,
                            semanaId: categoriasAlmuerzoSemanaActual[0].semanaId,
                            tipoAlmuerzoId: tipoAlmuerzo.tipoAlmuerzoId
                        });
                    }
                }
            }
    }

    return arrDataApi;
}