import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from "react-hook-form";
import Select from "react-select";
import { Typography } from '@mui/material';
import { FaFileExcel } from "react-icons/fa";
import { Encabezado } from '../../../Components/Encabezado/Encabezado';
import swal from "sweetalert";
import Loading from "../../../Components/Modals/Loading/Loader";

import { 
    selectAllAnosAbsentismo,
    fetchListaAnosAbsentismo 
} from '../../../redux/Utility/UtilitySlice';

import { 
    selectIsLoading,
    selectIsSuccess,
    fetchCambiarSuccess,
    fetchReporteAbsentismo 
} from '../../../redux/Reportes/ReporteAsistenciasSlice';


export const Absentismo = () => {

    // * OBTENER EL AÑO ACTUAL
    const ano = new Date().getFullYear();

    // ? HOOK-FORM
    const {
        register,
        watch,
        formState: { errors },
        control,
        reset
    } = useForm({
        defaultValues: {
            ano: ano
        }
    });
  

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const listaAnos = useSelector(selectAllAnosAbsentismo);
    const isLoading = useSelector(selectIsLoading);
    const isSuccess = useSelector(selectIsSuccess);

    // ? HOOK-EFFECT
    useEffect(() => {
        dispatch(fetchListaAnosAbsentismo());
    }, [])

    // ? USE-EFFECT PARA EL SWEET-ALERT.
    useEffect(() => {
    
        if(isSuccess === 'success'){
            swal("Exitoso!", "El reporte fue generado.", "success");
            dispatch(fetchCambiarSuccess());
        }
        else if(isSuccess === 'error'){
            swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
            dispatch(fetchCambiarSuccess());
        }

    }, [isSuccess])



    // * FUNCION PARA GENERAR EXCEL DE ABSENTISMO.
    const generateExcel = () => {

        // * DESESTRUCTURACION DE DATOS.
        const { ano } = watch();

        dispatch(fetchReporteAbsentismo(ano));
    }

    return (
        <Encabezado title='Absentismo'>

            <div className='row'>
                <div className='col-4'>
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">

                                <Typography className='fw-bold' fontSize={14}>Año</Typography>
                                <Controller
                                control={control}
                                name="ano"
                                {...register("ano")}
                                render={({ field }) => (
                                    <Select
                                    classNamePrefix="select"
                                    isSearchable={true}
                                    placeholder="Seleccionar Año"
                                    options={listaAnos}
                                    getOptionLabel={(option) => option.ano}
                                    getOptionValue={(option) => option.ano}
                                    onChange={(e) => field.onChange(e.ano)}
                                    value={listaAnos.find(x => x.ano === field.value)}
                                    />
                                )}
                                />
                                
                                <div className='mt-3 d-flex justify-content-end'>
                                    <button onClick={() => generateExcel()} id="btn-generar-reporte" className="btn btn-success">Generar EXCEL <FaFileExcel style={{marginTop: -3}} fontSize={20} /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* PANTALLA DE CARGA */}
            <Loading open={isLoading} />

        </Encabezado>
    )
}
