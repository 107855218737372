// export const apiBase = "https://localhost:44335/";
// export const apiBase = "http://localhost:8282/";
// export const apiBase = "https://localhost:5001/";
// export const apiBase = "http://172.16.75.51:93/";//
export const apiBase = "https://sirehv2-api.mem.gob.do/";

export const endpoints = {
  fetchEmpleadoImagen: "https://sireh.mem.gob.do/img/profile/",
  empleados: {
    deleteEmpleadoIdioma: `${apiBase}empleado/idioma/eliminar`,
    fetchEmpleados: `${apiBase}empleados`,
    fetchAllEmpleados: `${apiBase}empleadosall`,
    fetchEmpleado: `${apiBase}empleado`,
    fetchEmpleadosCount: `${apiBase}empleados/total`,
    fetchCumpleanos: `${apiBase}empleado/cumpleanos`,
    panelControl: `${apiBase}empleado/panelControl`,
    saveImagen: `${apiBase}empleado/imagen`,
    fetchGetReporteDinamico: `${apiBase}empleado/getReporteDinamico`,
    fetchGetReporteDinamicoExcel: `${apiBase}empleado/ReporteDinamicoExcel`,
    fetchGetReporteDinamicoCount: `${apiBase}empleado/getReporteDinamicoCount`,
    fetchGetEmpleadoByCedulaOrCodigoEmpleado: `${apiBase}empleado/getByCedula_Codigo`,
    fetchGetEmpleadoDashboard: `${apiBase}empleado/dashboard`
  },
  areasOrganizacionales: {
    fetchAreasOrganizacionalesLista: `${apiBase}areaorganizacionales/lista`,
    fetchAreasOrganizacionales: `${apiBase}areaorganizacionales`,
    fetchAreasOrganizacional: `${apiBase}areaorganizacional`,
    fetchAreaOrganizacionalAgregar: `${apiBase}areaorganizacional/nuevo`,
    fetchAreaOrganizacionalActualizar: `${apiBase}areaorganizacional/actualizar`,
    fetchAreaOrganizacionalRemover: `${apiBase}areaorganizacional/remover`,
  },
  permisos: {
    fetchTiposPermisosCategorias: `${apiBase}tipospermisosCategorias`,
    fetchTiposPermisos: `${apiBase}tipospermisos`,
    saveTipoPermisos: `${apiBase}tipopermiso/nuevo`,
    updateTipoPermisos: `${apiBase}tipopermiso/editar`,
    deleteTipoPermisos: `${apiBase}tipopermiso/eliminar`,
    fetchPermisos: `${apiBase}permisos`,
    fetchPermisosCount: `${apiBase}permisostotal`,
    fetchPermiso: `${apiBase}permiso`,
    savePermiso: `${apiBase}permiso/nuevo`,
    updatePermiso: `${apiBase}permiso/editar`,
    deletePermiso: `${apiBase}permiso/eliminar`,
    reportePermisosExportar: `${apiBase}ReportedePermisos/Exportar`,
    reportePermisosExportarExcel: `${apiBase}ReportedePermisos/ExportarExcel`,
    permisosCount: `${apiBase}PermisoEstadosCount`,
    aprobarPermisoEmpleadoEncargado: `${apiBase}permiso/aprobarPermisoEmpleadoEncargado`,
    aprobarPermisoEmpleadoRRHH: `${apiBase}permiso/aprobarPermisoEmpleadoRRHH`,
    rechazarPermisoEmpleado: `${apiBase}permiso/rechazarPermiso`,
    verificarEncargado: `${apiBase}permiso/VerificarEncargado`,
    reportePermisoAprobado: `${apiBase}ReporteSolicituddePermisos/Exportar`,
    eliminarPermiso: `${apiBase}permiso/RemoverPermiso`,
    fetchPermisosAreaReporteId: `${apiBase}permisos/areaReporteId`,
    permisosCountAreaReporteId: `${apiBase}PermisoAreaReporteIdCount`,
  },
  horarios: {
    fetchHorarios: `${apiBase}horarios`,
    fetchHorario: `${apiBase}horario`,
    saveHorario: `${apiBase}horario/nuevo`,
    updateHorario: `${apiBase}horario/editar`,
    deleteHorario: `${apiBase}horario/eliminar`,
  },
  diasFeriados: {
    fetchDiasFeriados: `${apiBase}diasferiados`,
    fetchDiasFeriadosAnoActual: `${apiBase}diaferiado/diasFeriadosAnoActual`,
    saveDiaferiado: `${apiBase}diaferiado/nuevo`,
    updateDiaferiado: `${apiBase}diaferiado/editar`,
    deleteDiaferiado: `${apiBase}diaferiado/eliminar`,
  },
  utility: {
    fetchTiposEmpleados: `${apiBase}tiposempleados`,
    fetchTipoEmpleado: `${apiBase}tipoempleado`,
    fetchEstadosCiviles: `${apiBase}estadociviles`,
    fetchEstadoCivile: `${apiBase}estadocivil`,
    fetchProgramas: `${apiBase}programas`,
    fetchPrograma: `${apiBase}programa`,
    fetchGrupoOcupacionales: `${apiBase}grupoocupacionales`,
    fetchGrupoOcupacional: `${apiBase}grupoocupacional`,
    fetchParentescos: `${apiBase}parentescos`,
    fetchEstudiosNiveles: `${apiBase}estudiosNiveles`,
    fetchIdiomas: `${apiBase}idiomas`,
    fetchNacionalidades: `${apiBase}nacionalidades`,
    fetchTipoSangre: `${apiBase}tipoSangre`,
    fetchAfpPensiones: `${apiBase}afpPensiones`,
    fetchSeguros: `${apiBase}seguros`,
    fetchTipoFamiliares: `${apiBase}tipoFamiliares`,
    fetchEnfermedades: `${apiBase}enfermedades`,
    fetchHabitos: `${apiBase}habitos`,
    fetchListaAnosAbsentismo: `${apiBase}absentismo/listaAnos`,
    fetchEstadosEmpleado: `${apiBase}estadosEmpleado`,
    fetchUbicaciones: `${apiBase}ubicaciones`,
    fetchEmpleadoFirmaExiste: `${apiBase}empleadoFirmaExiste`
  },
  permisosGenerales: {
    fetchPermisosGenerales: `${apiBase}permisosgenerales`,
    savePermisosGenerales: `${apiBase}permisogeneral/nuevo`,
    updatePermisosGenerales: `${apiBase}permisogeneral/editar`,
    deletePermisosGenerales: `${apiBase}permisogeneral/eliminar`,
  },
  reporteAsistencias: {
    asistencias: `${apiBase}reporteasistencias`,
    reporteAsistenciaExportar: `${apiBase}reporteasistencias/ExportarAsistencia`,
    resumenAsistencia: `${apiBase}reporteasistencias/resumenAsistencia`,
    resumenAsistenciaExportar: `${apiBase}reporteAsistencias/ExportarResumenAsistencia`,
    reporteAbsentismo: `${apiBase}reporteAsistencias/Absentismo`,
  },
  evaluaciones: {
    listaEvaluaciones: `${apiBase}evaluaciones`,
    listaEvaluacionesPeriodo: `${apiBase}evaluaciones/periodo`,
    reporteMAP: `${apiBase}evaluaciones/reporte_map`,
    reporteDAF: `${apiBase}evaluaciones/reporte_daf`,
    evaluacionNueva: `${apiBase}evaluacion/nueva`,
    evaluacionActualizar: `${apiBase}evaluacion/modificar`,
    evaluacionEliminar: `${apiBase}evaluacion/eliminar`,
    evaluacionPeriodoEstado: `${apiBase}periodos`,
    evaluacionNotificarPeriodo: `${apiBase}evaluacion/notificar/periodo`,
    evaluacionGenerarPeriodo: `${apiBase}evaluaciones/generar/periodo`,
    evaluacionEditarPeriodo: `${apiBase}evaluacion/periodo/editar`,
    evaluacionCrearPeriodo: `${apiBase}evaluacion/periodo/registrar`,
    evaluacionCerrarPeriodo: `${apiBase}evaluacion/periodo/cerrar`,
    evaluacionResultadosHash: `${apiBase}evaluacion/hash`,
    evaluacionLogroMetasAgregar: `${apiBase}evaluacion/evaluacion_resultados_valores`,
    evaluacionLogroMetasEditar: `${apiBase}evaluacion/evaluacion_resultados_valores/editar`,
    evaluacionLogroMetasEliminar: `${apiBase}evaluacion/evaluacion_resultados_valores/remover`,
    evaluacionLogroMetasAllPorUsuario: `${apiBase}evaluacion/evaluacion_resultados_valores/evaluacionId`,
    evaluacionAprobacionLogroMetas: `${apiBase}evaluacion/evaluacion_aprobacion`,
    evaluacionPlanMejora: `${apiBase}evaluacion/Plan_de_Mejora_y_Desarrollo_Profesional`,
    evaluacionPlanMejoraEditar: `${apiBase}evaluacion/Plan_de_Mejora_y_Desarrollo_Profesional/editar`,
    evaluacionReabrir: `${apiBase}evaluacion/periodo/evaluacion/reabrir`,
    evaluacionCerrar: `${apiBase}evaluacion/periodo/evaluacion/cerrar`,
    evaluacionPuntosFuertesRemover: `${apiBase}evaluacion/evaluacion/puntosfuertes/remover`,
    evaluacionAreasMejorasRemover: `${apiBase}evaluacion/evaluacion/areasmejoras/remover`,
    evaluacionGenerarReporteEvaluacionEmpleado: `${apiBase}evaluacion/acuerdo`,
    fetchGetCompetenciasComportamientos: `${apiBase}evaluacion/competencias/`,
    fetchUpdateCompetenciasComportamientos: `${apiBase}evaluacion/competencias/updateComportamientos`,
    fetchPostResultadosValoresFiles: `${apiBase}evaluacion/resultadosValores/Files`,
    fetchDeleteResultadosValoresFiles: `${apiBase}evaluacion/resultadosValores/Files/remove/`,
    fetchGetReporteEvaluacionesPeriodoMAP2024: `${apiBase}evaluacion/reportePeriodo/`
  },
  login: {
    authenticate: `${apiBase}login/authenticate`,
    refreshToken: `${apiBase}login/refresh`,
    changePassword: `${apiBase}login/ChangePassword`,
  },
  cargos: {
    fetchLista: `${apiBase}Cargos/lista`,
  },
  periodos: {
    listaPeriodos: `${apiBase}Periodos`,
  },
  gruposOcupacionales: {
    listaGrupoOcupacionales: `${apiBase}gruposOcupacionales`,
  },
  beneficios: {
    fetchBonoEscolarPeriodo: `${apiBase}beneficios/bonoescolar`,
    fetchBonoEscolarPorPeriodo: `${apiBase}beneficios/bonoescolar/beneficiarios/periodo`,
    fetchBonoEscolarCrearPeriodo: `${apiBase}beneficios/bonoescolar/nuevo`,
    fetchBonoEscolarEditarPeriodo: `${apiBase}beneficios/bonoescolar/editar`,
    fetchBonoEscolarCerrarPeriodo: `${apiBase}beneficios/bonoescolar/beneficiarios/cerrar`,
    fetchBonoEscolarBeneficiariosActualizar: `${apiBase}beneficios/bonoescolar/beneficiarios/actualizar`,
    fetchBonoEscolarEditarBonoEmpleado: `${apiBase}beneficios/bonoescolar/beneficiarios/Editar`,
    fetchBonoEscolarEliminarBonoEmpleado: `${apiBase}beneficios/bonoescolar/beneficiarios/Remover`,
    fetchBonoEscolarEstadoPeriodo: `${apiBase}beneficios/bonoescolar/id`,
    fetchBonoEscolarExportarExcelPeriodo: `${apiBase}beneficios/reporte/Beneficiarios_Bono_Escolar`,
  },
  nominas: {
    listaPeriodosNominasVer: `${apiBase}ver_nominas_periodos`,
    listaPeriodosNominasInsetar: `${apiBase}ver_nominas_periodos`,
    filtrarNominasEmpleados: `${apiBase}filtrar_nominas_empleados`,
    verNominaEmpleado: `${apiBase}ver/empleado`,
    listaProgramas: `${apiBase}ver_nominas_programas`,
    imprimirReporteNominaEmpleado: `${apiBase}imprimir/empleado`,
    generarAñosSubirNomina: `${apiBase}nomina/subir_periodos`,
    subirNomina: `${apiBase}nomina/cargar`,
    listaNominasDetalle: `${apiBase}nomina/lista`,
    eliminarNomina: `${apiBase}nomina/eliminar`,
    fetchVerMisNominas: `${apiBase}nomina/ver/mi_nominas`,
    fetchEnviarCorreos: `${apiBase}nomina/enviarCorreos`,
    fetchVerificarEstadoEnvioCorreos: `${apiBase}nomina/verificarEstadoEnvioCorreos`,
  },
  cafeteria: {
    buscarEmpleado: `${apiBase}empleado/buscar`,
    agregarComida: `${apiBase}Cafeteria/AgregarComida`,
    generarReporteHistoricoCafeteria: `${apiBase}Cafeteria/ReporteHistorico`,
    generarReporteEstadisticaCafeteria: `${apiBase}Cafeteria/ReporteEstadistica`,
    generarReporteCafeteriaSemanal: `${apiBase}Cafeteria/ReportMenuComidaEmpleadoPorAreaOrganizacional`,
    generarReporteLabelsSemanalCafeteria: `${apiBase}Cafeteria/ReportMenuComidaEmpleadoSemana`,
    getSemanaAlmuerzoActiva: `${apiBase}Cafeteria/SemanaAlmuerzoActiva`,
    getDiaAlmuerzo: `${apiBase}Cafeteria/DiasAlmuerzo`,
    getCategoriasAlmuerzo: `${apiBase}Cafeteria/CategoriasAlmuerzo`,
    getCategoriasAlmuerzoSemanaActiva: `${apiBase}Cafeteria/CategoriasAlmuerzoSemanaActiva`,
    getMenuComidaEmpleadoSemana: `${apiBase}Cafeteria/GetMenuComidaEmpleadoSemana`,
    getSemanasAlmuerzo: `${apiBase}Cafeteria/SemanasAlmuerzo`,
    fetchSaveMenuComidaEmpleadoSemana: `${apiBase}Cafeteria/AgregarMenuComidaEmpleado`,
    fetchValidacionSubsidioComidaEmpleadoAplica: `${apiBase}Cafeteria/ValidacionSubsidioComidaEmpleadoAplica`,
    fetchSaveMenuComidaRRHH: `${apiBase}Cafeteria/AgregarMenuComida`,
    getComidas: `${apiBase}Cafeteria/Comidas`
  },
  notificaciones: {
    hub: `${apiBase}Notification`,
    getNotificacionesEmpleado: `${apiBase}notificaciones/getNotificacionesEmpleado`,
    marcarComoLeido: `${apiBase}Notificaciones/MarcarComoLeido`,
  },
  acciones: {
    createReclutamiento: `${apiBase}Acciones/CreateReclutamiento`,
    createPersonal: `${apiBase}Acciones/CreatePersonal`,
    updateAccion: `${apiBase}Acciones/update`,
    getListAccionesPersonal: `${apiBase}Acciones/GetListAccionesPersonal`,
    getListAccionesReclutamiento: `${apiBase}Acciones/GetListAccionesReclutamiento`,
    procesarAccionReclutamiento: `${apiBase}Acciones/ProcesarAccionReclutamiento`,
    procesarAccionPersonal: `${apiBase}Acciones/ProcesarAccionPersonal`,
    cancelarAccionPersonal: `${apiBase}Acciones/CancelarAccionPersonal`,
    cancelarAccionReclutamiento: `${apiBase}Acciones/CancelarAccionReclutamiento`,
    reporteAccion: `${apiBase}Acciones/reporte/AccionPersonalNuevo`,
    getAccionById: `${apiBase}Acciones`,
    getCountAccionesReclutamiento: `${apiBase}Acciones/GetCountAccionReclutamiento`,
  },
  tipoAccion: {
    getListaTipoAcciones: `${apiBase}TipoAccion/GetListaTipoAcciones`,
  },
  historicoClinico: {
    createHistoricoClinico: `${apiBase}HistorialClinico/crear`,
    updateHistoricoClinico: `${apiBase}HistorialClinico/actualizar`,
    getListHistoricoClinico: `${apiBase}HistorialClinico`,
    countListHistoricoClinico: `${apiBase}HistorialClinico/Count`,
    getHistorialClinicoById: `${apiBase}HistorialClinico`,
    getEmpleado: `${apiBase}HistorialClinico/getEmpleado`,
    generarReportePDF: `${apiBase}HistorialClinico/Report`,
  },
  sorteo: {
    getEmpleados: `${apiBase}Rifa/getEmpleados`,
    getEmpleadoById: `${apiBase}Rifa/getEmpleado`,
    saveEmpleadoRifa: `${apiBase}Rifa/saveEmpleadoRifa`,
    saveGanador: `${apiBase}Rifa/saveGanador`
  },
};
