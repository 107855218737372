import React, { useState, useEffect } from 'react';
import { Search } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import Firma from '../../../Components/Firma/Firma';
import AprobacionesCard from '../../../Components/Permisos/AprobacionesCard';
import Loading from '../../../Components/Modals/Loading/Loader';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from '@mui/material/Checkbox';
import swal from 'sweetalert';

import {
	selectIsLoading,
	selectIsSuccess,
	selectMessage,
	fetchCountPermisos,
	selectPermisosCount,
	fetchPermisosAll,
	fetchCambiarSuccess,
	selectPermisos
} from '../../../redux/Permisos/PermisosSlice';

import { 
	fetchEmpleadoFirmaExiste, 
	selectEmpleadoFirmaExiste 
} from '../../../redux/Utility/UtilitySlice';

import { 
	fetchListDiasFeriadosAnoActual
} from '../../../redux/DiasFeriados/DiasFeriadosSlice';

import { 
	aprobarPermisoEmpleadoRRHH
} from "../../../redux/Permisos/PermisosSlice";

import { selectUserData } from "../../../redux/Login/LoginSlice";

export const Aprobaciones = () => {

	// ? HOOK-DISPATCH
	const dispatch = useDispatch();

	// ? HOOK SELECTOR
	const usuario = useSelector(selectUserData);
	const isLoading = useSelector(selectIsLoading);
	const isSuccess = useSelector(selectIsSuccess);
	const message = useSelector(selectMessage);
	const permisos = useSelector(selectPermisos);
	const permisosCount = useSelector(selectPermisosCount);
	const validacionFirmaEmpleado = useSelector(selectEmpleadoFirmaExiste);

	// ? HOOK-STATE
	const [ page, setPage ] = useState(0);
	const [ rowsPerPage, setRowsPerPage ] = useState(25);
	const [ skipRow, setSkipRow ] = useState(0);
	const [ changeEmpleado, setChangeEmpleado ] = useState('');
	const [ empleado, setEmpleado ] = useState('');
	const [ enabledEmpleadosActivos, setEnabledEmpleadosActivos ] = useState(false);

	// ? HOOK-STATE PARA ABRIR EL MODAL DE FIRMA.
	const [openFirma, setOpenFirma] = useState(false);
	
	// ? HOOK-STATE PARA ENVIO DE DATA CON FIRMA
	const [dataFirma, setDataFirma] = useState({file: '', permisoId: 0});
	const [validateSendDataToApi, setValidateSendDataToApi] = useState(false);

	const refreshList = () => {

		const aprobacionesFilter = {
			empleado: empleado ?? '',
			estadoPermiso: 0,
			empleadoId: 0,
			estadoEmpleado: enabledEmpleadosActivos ? 0 : 1,
			areaOrganizacional: 0
		};

		dispatch(fetchCountPermisos(aprobacionesFilter));
		dispatch(fetchPermisosAll({aprobacionesFilter, skipRow, rowsPerPage}));
	}
	
	// ? HOOK-EFFECT
	useEffect(() => {
		refreshList();
	}, [skipRow, rowsPerPage, empleado, enabledEmpleadosActivos]);


	// * FUNCIONES DE PAGINACION.
	const handleChangePage = (event, newPage) => {
		setSkipRow(newPage * rowsPerPage);
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};

	const handleEmpleado = () => {
		setEmpleado(changeEmpleado);
	};

	useEffect(() => {
    
        if(isSuccess === 'success' && message.length > 0){
          swal("Exitoso!", message, "success");
		  refreshList();
          dispatch(fetchCambiarSuccess());
		  setOpenFirma(false);
        }
        else if(isSuccess === 'error'){
          swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
          dispatch(fetchCambiarSuccess());
        }
    
    }, [isSuccess])

	useEffect(() => {
	  dispatch(fetchListDiasFeriadosAnoActual())
	  refreshValidacionFirmaEmpleado();
	}, [])

	// ? HOOK-EFFECT PARA ENVIAR DATA A API CUANDO SE REQUIERE UNA ACTUALIZACION O CREACION DE FIRMA
	// ? APLICA AL MOMENTO DE APROBAR UN PERMISO.
	useEffect(() => {

	if(validateSendDataToApi){
		dispatch(aprobarPermisoEmpleadoRRHH({ ...dataFirma, usuarioId: usuario.Id }));
		setValidateSendDataToApi(false);
	}

	}, [validateSendDataToApi])

	// * FUNCION PARA REFRESCAR LA VALIDACION DE LA FIRMA DEL EMPLEADO
	const refreshValidacionFirmaEmpleado = () => dispatch(fetchEmpleadoFirmaExiste({empleadoId: usuario.Id}));

	return (

		<>
			<div className="container-fluid main">
				<div className="row mt-2 mb-3">
					<div className="col-md-12">
						<div>
							<h3 className="text-primary-mem">Permisos</h3>
							<hr />
						</div>

						<div className="row bg-primary-mem text-center ms-1 me-1">
							<h3 className="text-white">Aprobaciones Recursos Humanos</h3>
						</div>

						<div className="row mt-2">
								
							<div className='d-flex justify-content-end'>

								<div className="col-6">
									<div className="input-group">

										<div className='me-4'>
											<label className="align-self-center me-2">EMPLEADO INACTIVOS</label>
											<Checkbox id='checkbox-empleados-inactivos' checked={enabledEmpleadosActivos} onChange={e => setEnabledEmpleadosActivos(e.target.checked)} />
										</div>
									
										<label className="align-self-center me-3">EMPLEADO</label>
										<input
											type="text"
											className="form-control"
											id="input-empleado"
											value={changeEmpleado}
											onChange={(e) =>{
												e.preventDefault();
												setChangeEmpleado(e.target.value)
											} 
										}
										/>

										<button
										id='btn-search'
										onClick={()=>handleEmpleado()}
										type="button"
										className="input-group-text btn-primary-mem"
										>
											<Search />
										</button>

									</div>
								</div>

							</div>

						
						</div>

						{permisos.length > 0 &&
							permisos.map((permiso) => (
								<AprobacionesCard 
								key={permiso.id} 
								setDataFirma={setDataFirma} 
								setValidateSendDataToApi={setValidateSendDataToApi}
								dataFirma={dataFirma}
								setOpenFirma={setOpenFirma} 
								permiso={permiso} 
								validacionFirmaEmpleado={validacionFirmaEmpleado} 
								/>
							))
						} 

						<Loading open={isLoading} />
					</div>
				</div>

				<TablePagination
					component="div"
					count={permisosCount}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>

				<Firma 
				setOpen={setOpenFirma}
				open={openFirma}
				setDataFirma={setDataFirma}
				setValidateSendDataToApi={setValidateSendDataToApi}
				dataFirma={dataFirma}
				/>

			</div>
		</>
  );
};

export default Aprobaciones;
