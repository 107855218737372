import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { 
    Dialog,
    DialogTitle,
    Divider,
    DialogContent,
    DialogActions,
    Typography
} from '@mui/material';

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import SaveIcon from '@mui/icons-material/Save';

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { fetchCambiarContraseñaUsuarioExterno } from '../../../redux/Login/LoginSlice';

export const CambiarContraseñaModal = ({open = false, setOpen, usuario, resetForm, setResetForm}) => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // * VALIDACIONES DEL HOOK-FORM PARA LAS CONTRASEÑAAS.
    const formSchema = Yup.object().shape({
        contraseñaActual: Yup.string()
          .required("El campo es requerido"),
        contraseñaNueva: Yup.string()
          .required("El campo es requerido.")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            'La contraseña debe contener al menos 8 caracteres, incluyendo letras y números'
          ),
        repetirContraseña: Yup.string()
            .required("El campo es requerido.")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                'La contraseña debe contener al menos 8 caracteres, incluyendo letras y números'
            )
            .oneOf([Yup.ref("contraseñaNueva")], "Las contraseñas no son iguales. Favor verificar.")
    });

    // ? HOOK-FORM
    const { 
        register,  
        formState: { errors },
        handleSubmit,
        reset
    
    } = useForm({
        mode: "onTouched",
        resolver: yupResolver(formSchema)
    })

    // * ENVIANDO DATA A API.
    const onSubmit = (data) => {
        dispatch(fetchCambiarContraseñaUsuarioExterno({...data, empleadoId: usuario.Id}));
    };

    useEffect(() => {
      if(resetForm){
        reset();
        setResetForm(false);
        setOpen(false);
      }
    }, [resetForm])
    

return (
    <Dialog
    PaperProps={{ sx: { width: "32%", height: "45%" } }}
    open={open}
    maxWidth='lg'
    >
        <DialogTitle sx={{textTransform: 'uppercase'}} className='text-mem text-center'>Cambiar contraseña</DialogTitle>
        <Divider />
            
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>

                    
                    <div className="form-group">
                        <Typography fontWeight='bold'>Contraseña Actual</Typography>
                        <input
                        type='password'
                        id='contraseñaActual'
                        className="form-control"
                        {...register("contraseñaActual")}
                        />
                        <p className="text-danger">
                        {errors?.contraseñaActual?.message}
                        </p>
                    </div>

                    <div className="form-group">
                    <Typography fontWeight='bold' mt={2}>Contraseña Nueva</Typography>
                        <input
                        type='password'
                        id='contraseñaNueva'
                        className="form-control"
                        {...register("contraseñaNueva")}
                        />
                        <p className="text-danger">
                        {errors?.contraseñaNueva?.message}
                        </p>
                    </div>

                    <div className="form-group">
                        <Typography fontWeight='bold' mt={2}>Repetir Contraseña</Typography>
                        <input
                        type='password'
                        id='repetirContraseña'
                        className="form-control"
                        {...register("repetirContraseña")}
                        />
                        <p className="text-danger">
                        {errors?.repetirContraseña?.message}
                        </p>
                    </div>

                </DialogContent>

                <Divider />

                <DialogActions className='justify-content-center'>
                    <button onClick={() => setOpen(false)} id='btn-cancelar' type='button' className='btn btn-danger'><DoNotDisturbAltIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>CANCELAR</button>
                    <button id='btn-guardar' className='btn btn-primary-mem'><SaveIcon sx={{marginTop: -0.5, marginRight: 0.5}}/>GUARDAR</button>
                </DialogActions>
            </form>

    </Dialog>
    )
}
