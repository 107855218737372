import React, { 
    useState,
    useEffect
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import swal from 'sweetalert';

import { 
    selectDiasAlmuerzo,
    selectCategoriasAlmuerzoSemanaActiva,
    selectCategoriasAlmuerzo,
    selectIsLoading,
    selectIsSuccess,
    selectMessage,
    selectValidacionSubsidioComidaEmpleadoAplica,
    fetchGetDiasAlmuerzo,
    fetchCambiarSuccess,
    selectMenuComidaEmpleadoSemana,
    fetchGetCategoriasAlmuerzoSemanaActiva,
    fetchGetCategoriasAlmuerzo,
    fetchSaveMenuComidaEmpleadoSemana,
    fetchGetMenuComidaEmpleadoSemana,
    fetchValidacionSubsidioComidaEmpleadoAplica
} from '../../../redux/Cafeteria/CafeteriaSlice';

import { selectUserData } from "../../../redux/Login/LoginSlice";

import { SeleccionAlmuezoEmpleadoPage } from '../Page/SeleccionAlmuezoEmpleadoPage';

import { 
    generarSeleccionMenuEmpleado,
    generarAlmuerzoMenuListEmpleado,
    generarResumenMenu,
    generarListaSeleccionComidaEmpleadoApi
} from '../../../pages/Cafeteria/Functions/CafeteriaFunctionsDigitarComida';

import { 
    Step, 
    StepButton, 
    Stepper, 
    Typography
} from '@mui/material';

import Loading from '../../../Components/Modals/Loading/Loader';

export const RegistrarMenusTabs = () => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const menuEmpleadoSemana = useSelector(selectMenuComidaEmpleadoSemana)
    const diasSemanas = useSelector(selectDiasAlmuerzo);
    const categoriasAlmuerzoSemanaActual = useSelector(selectCategoriasAlmuerzoSemanaActiva)
    const categorias = useSelector(selectCategoriasAlmuerzo);
    const { Id : usuarioId} = useSelector(selectUserData);
    const isLoading = useSelector(selectIsLoading);
    const message = useSelector(selectMessage);
    const success = useSelector(selectIsSuccess);
    const validacionAplicaSubsidioComida = useSelector(selectValidacionSubsidioComidaEmpleadoAplica);

    // ? HOOK-STATE PARA LA PAGINACION DE PAGINAS.
    const [stepPosition, setStepPosition] = useState({position: 0, diaId: 1}); 

    // ? HOOK-STATE MENU ALMUERZO DIA EMPLEADO.
    const [almuerzoMenuListEmpleado, setAlmuerzoMenuListEmpleado] = useState([]);

    // ? HOOK-STATE SELECCION DE COMIDA POR DIA DEL EMPLEADO
    const [seleccionMenuEmpleado, setSeleccionMenuEmpleado] = useState({});

    // ? HOOK-STATE PARA GUARDAR EL RESUMEN DEL MENU DEL EMPLEADO
    const [resumenMenuState, setResumenMenuState] = useState({});

    // ? HOOK STATE PARA ALMACENAR LOS DIAS DE LUNES A VIERNES Y ADICIONAL EL RESUMEN DEL MENU SEMANAL.
    const [diasSemanaState, setDiasSemanaState] = useState([]);

    // ? HOOK-EFFECT PARA REFRESCAR TODAS LAS LISTAS
    useEffect(() => {
        dispatch(fetchValidacionSubsidioComidaEmpleadoAplica({empleadoId: usuarioId}));
        refreshAll();
    }, [])

    // ? HOOK-EFFECT PARA SWEET-ALERT
    useEffect(() => {

        if (success === "success" && message.length > 0) {
            swal("Exitoso!", message, "success", {
                timer: 1200,
            });

            // * RESET STATE.
            setStepPosition({position: 0, diaId: 1});
            dispatch(fetchCambiarSuccess());
            
            // * REFRESCAR TODAS LAS LISTAS NUEVAMENTE.
            refreshAll();
        } 
        else if (success === "error") {
            swal("Error", message, "error", {
                timer: 5000
            });

            dispatch(fetchCambiarSuccess());
        }
    }, [success]);

    // ? HOOK-EFFECT PARA GENERAR LA SELECCION DE MENU COMIDA DEL EMPLEADO.
    useEffect(() => {        
        setAlmuerzoMenuListEmpleado(generarAlmuerzoMenuListEmpleado(diasSemanas, categoriasAlmuerzoSemanaActual, usuarioId));
        setSeleccionMenuEmpleado(generarSeleccionMenuEmpleado(diasSemanas, categorias, menuEmpleadoSemana));
    }, [diasSemanas, categorias, menuEmpleadoSemana, categoriasAlmuerzoSemanaActual])
    

    // ? HOOK-EFFECT PARA GENERAR EL RESUMEN DEL MENU DE LA SEMANA.
    useEffect(() => {
        setResumenMenuState(generarResumenMenu(seleccionMenuEmpleado, categoriasAlmuerzoSemanaActual, diasSemanas));
    }, [seleccionMenuEmpleado])


    // ? HOOK-STATE PARA AÑADIR UN NUEVO DIA DE SEMANA QUE ES EL RESUMEN DE LA SELECCION.
    useEffect(() => {
      setDiasSemanaState([...diasSemanas, { id: 6, descripcion: "RESUMEN DE LA SELECCIÓN"}]);
    }, [diasSemanas])


    // * FUNCION PARA GUARDAR LA DATA SELECCIONADA EN LA API.
    const sendDataToApi = () => {
        
        // * FUNCION QUE GENERA EL ARRAY DE OBJETOS QUE SERA ENVIADO A LA API.
        const arrDataApi = generarListaSeleccionComidaEmpleadoApi(menuEmpleadoSemana, seleccionMenuEmpleado, categoriasAlmuerzoSemanaActual, diasSemanas);
    
        // * GUARDANDO DATA.
        dispatch(fetchSaveMenuComidaEmpleadoSemana({empleadoId: usuarioId, data: arrDataApi}))
    }

    // * REFRESH-ALL
    const refreshAll = () => {
        dispatch(fetchGetMenuComidaEmpleadoSemana({empleadoId: usuarioId}))
        dispatch(fetchGetDiasAlmuerzo())
        dispatch(fetchGetCategoriasAlmuerzoSemanaActiva())
        dispatch(fetchGetCategoriasAlmuerzo())
    }

     // * COMPONENTE PARA ACTUALIZAR LA PAGINA SEGUN EL DIA ELEGIDO.
     const HandleSteps = ({ position }) => {

        let children = <></>;

        const categoriaAlmuerzoDiaEmpleado = almuerzoMenuListEmpleado.filter(x => x.diaId === position.diaId).map(x => x.categoriaAlmuerzo).flat();

       if(validacionAplicaSubsidioComida){
        children = 
        <SeleccionAlmuezoEmpleadoPage
        diasSemanas={diasSemanas}
        categoriaAlmuerzoDiaEmpleado={categoriaAlmuerzoDiaEmpleado}
        setStepPosition={setStepPosition}
        stepPosition={stepPosition}
        setSeleccionMenuEmpleado={setSeleccionMenuEmpleado}
        seleccionMenuEmpleado={seleccionMenuEmpleado}
        resumenMenuState={resumenMenuState}
        sendDataToApi={sendDataToApi}
        />;        

       }
       else{
        children = <Typography variant='h5' align='center' mt={10}>Usted no aplica para este subsidio.</Typography>
       }

        return children;
    };

    return (
        <>
            <Stepper disabled={!validacionAplicaSubsidioComida} alternativeLabel activeStep={menuEmpleadoSemana.length > 0 ? diasSemanaState.length : stepPosition.position} sx={{ marginTop: 2 }}>
            
            {
                diasSemanaState.length > 0 &&
                    diasSemanaState.map((item, index) =>

                        <Step key={index}>
                            <StepButton disabled={!validacionAplicaSubsidioComida} onClick={() => setStepPosition({position: index, diaId: item.id})}>
                                {item.descripcion}
                            </StepButton>
                        </Step>
                    )
            }

            </Stepper>

            <div style={{ marginTop: '3%' }}>
                <HandleSteps position={stepPosition} />
            </div>

            <Loading open={isLoading} />
        </>
    );
};