import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";

const initialState = {
    isLoading: false,
    isSuccess: 'idle',
    message: "",
    notificaciones: []
}

const mensajeErrorDefault = "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.";

export const fetchCambiarSuccess = createAsyncThunk("notificaciones/fetchCambiarSuccess",
    async () => {
      return null;
    }
);  

// * BUSCAR NOTIFICACIONES DE UN EMPLEADO.
export const fetchNotificacionesEmpleado = createAsyncThunk("notificaciones/fetchNotificacionesEmpleado", 

    async (email) => {

        var resultado = await axiosApiInstance.get(`${endpoints.notificaciones.getNotificacionesEmpleado}?email=${email}`);
        return resultado.data;
    }
)

// * MARCAR COMO LEIDA UNA NOTIFICACION.
export const fetchMarcarComoLeido = createAsyncThunk("notificaciones/fetchMarcarComoLeido", 

    async (notificacionId) => {

        var resultado = await axiosApiInstance.post(`${endpoints.notificaciones.marcarComoLeido}?notificacionId=${notificacionId}`);
        return resultado.data;
    }
)

export const NotificacionesSlice = createSlice({
    name:"notificaciones",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchCambiarSuccess.fulfilled, (state) => {
            state.isSuccess = "idle";
        })
        .addCase(fetchNotificacionesEmpleado.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchNotificacionesEmpleado.fulfilled, (state, action) => {
            state.notificaciones = action.payload.data;
            state.isLoading = false;
        })
        .addCase(fetchNotificacionesEmpleado.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.notificaciones = [];
            state.message = mensajeErrorDefault;
            console.error(action.error.message);
        })
        .addCase(fetchMarcarComoLeido.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchMarcarComoLeido.fulfilled, (state, action) => {
            
            if(action.payload.isSuccess){
                state.isSuccess = 'success'; 
            }
            else
            {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
            }
            state.isLoading = false;
        })
        .addCase(fetchMarcarComoLeido.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = mensajeErrorDefault;
            console.error(action.error.message);
        })
        ;
    }
})

export const selectNotificacionesEmpleado = (state) => state.notificaciones.notificaciones;
export const selectIsLoading = (state) => state.notificaciones.isLoading
export const selectIsSuccess = (state) => state.notificaciones.isSuccess
export const selectMessage = (state) => state.notificaciones.message

export const {} = NotificacionesSlice.actions;
export default NotificacionesSlice.reducer;