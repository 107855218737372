import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Encabezado } from '../../../Components/Encabezado/Encabezado';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadingIcon from '@mui/icons-material/Downloading';
import { IconButtonText } from '../../../Components/IconPersonalize/IconButtonText';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { EditarBonoEscolarEmpleadoModal } from '../../../Components/Beneficios/Modal';
import Loading from "../../../Components/Modals/Loading/Loader";
import TablePagination from '@mui/material/TablePagination';
import { removeAccents } from '../../../utils/removeAccents';
import swal from "sweetalert";
import numbro from 'numbro';
import { formatNumberRD } from '../../../utils/formatNumberRD';

import { 
  fetchBonoEscolarPorPeriodo, 
  fetchBonoEscolarEmpleadoEliminar,
  fetchObtenerEstadoPeriodo,
  fetchGenerarExcelPeriodoBeneficiarios,
  selectBonoEscolarPorPeriodo,
  selectBonoEscolarEstadoPeriodo,
  fetchCambiarSuccess,
  selectIsSuccess,
  selectIsLoading,
  selectMessage 
} from '../../../redux/Beneficios/BonoEscolar/BonoEscolarSlice'

export const BonoEscolarDetalleAno = () => {
  
  // * PARAMETRO A TRAVES DE LA RUTA.
  const { ano } = useParams();

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const listBonoEscolarPorAno = useSelector(selectBonoEscolarPorPeriodo);
  const estadoPeriodo = useSelector(selectBonoEscolarEstadoPeriodo);
  const isSuccess = useSelector(selectIsSuccess);
  const isLoading = useSelector(selectIsLoading);
  const message = useSelector(selectMessage);

  // ? HOOK-STATE DE PAGINACION.
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(25);
  const [skipRow, setSkipRow] = useState(0);
  const [listaBonoEscolarLength, setListaBonoEscolarLength] = useState(0);

  // ? HOOK-STATE
  const [openModal, setOpenModal] = useState(false);
  const [listadoBonoEscolarDinamico, setListadoBonoEscolarDinamico] = useState([]);
  const [beneficiario, setBeneficiario] = useState({});

  // ? HOOK-STATE EMPLEADO
  const [empleado, setEmpleado] = useState('');

  // ? HOOK-NAVIGATE
  const navigate = useNavigate();

  // * ESTILOS DE LOS ICONOS.
  const iconStyle = {
    marginTop: -0.5, marginRight: 0.5
  }

  // * OPEN MODAL
  const openModalEdit = (item) => {
    setBeneficiario(item);
    setOpenModal(true);
  }

  // * REFRESH SUCCESS
  const refreshSuccess = () => dispatch(fetchCambiarSuccess());

  // * REFRESH LIST
  const refreshListBonoEscolarPeriodo = () => dispatch(fetchBonoEscolarPorPeriodo(ano));

  // * REFRESH ESTADO
  const refreshEstadoBonoEscolarPeriodo = () => dispatch(fetchObtenerEstadoPeriodo(ano));

  // * FUNCION QUE ELIMINA UNA BONIFICACION.
  const deleteBonificacion = (beneficiarioBonoEscolarId) => {
    swal({
        title: 'Eliminar',
        text: 'Estás seguro/a de eliminar esta bonificación?',
        icon: 'warning',
        buttons: ['No', 'Si']
    }).then(respuesta => {
        if(respuesta){
           dispatch(fetchBonoEscolarEmpleadoEliminar(beneficiarioBonoEscolarId));
        }
    })
  }

  // * FUNCION PARA LA PAGINACION
  const handleChangePage = (event, newPage) => {
    setSkipRow(newPage * rowsPerPage)
    setPage(newPage);
  };

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};

  // * FUNCION PARA GENERAR EL EXCEL DEL PERIODO.
  const generateExcelPeriodo = () => dispatch(fetchGenerarExcelPeriodoBeneficiarios(ano));


  // ? HOOK-EFFECT PARA REFRESCAR LISTA DE BONO ESCOLAR PERIODO.
  useEffect(() => {
    refreshListBonoEscolarPeriodo();
    refreshEstadoBonoEscolarPeriodo();
  }, [ano])

  // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
  useEffect(() => {

    let inicio = rowsPerPage * page;
    let final = (rowsPerPage * page) + rowsPerPage;

    if(empleado.length > 0){
      
      // * FILTRADO DE NOMBRE EN EL ARRAY.
      const listFilter = listBonoEscolarPorAno.filter((x) =>
      removeAccents(x.nombredelEmpleado).toLowerCase().includes(removeAccents(empleado.toLowerCase())));

      setListadoBonoEscolarDinamico(listFilter.slice(inicio, final));
      setListaBonoEscolarLength(listFilter.length);
    }
    else if(listBonoEscolarPorAno.length > 0){
      
      setListadoBonoEscolarDinamico(listBonoEscolarPorAno.slice(inicio, final));
      setListaBonoEscolarLength(listBonoEscolarPorAno.length);
    }

  }, [listBonoEscolarPorAno, page, skipRow, rowsPerPage, empleado])  
  
  // ? HOOK-EFFECT PARA LAS ALERTAS.
  useEffect(() => {
    
    if(isSuccess === 'success'){
      swal("Exitoso!", message, "success");
      refreshListBonoEscolarPeriodo();
      refreshSuccess();
    }
    else if(isSuccess === 'error'){
      swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
      refreshSuccess();
    }

  }, [isSuccess])  

  return (
    <Encabezado title={'Bono Escolar año'} ano={ano}>
      <div className='row border m-1 p-2'>
        <div className='col-md-12 ps-2 container'>
    
          <div className='d-flex justify-content-start'>
            <div className='flex-fill bd-highlight'>
              <button className='btn btn-primary-mem me-2' onClick={() => navigate('/beneficios/bonoescolar')}>
                  <ArrowBackIcon sx = {iconStyle} />
                  Volver
              </button>

              <button className='btn btn-success me-2' onClick={() => generateExcelPeriodo()} disabled={estadoPeriodo === 0}>
                  <DownloadingIcon sx={iconStyle} />  Exportar a Excel
               </button>

            </div>

            <div className="input-group w-25 justify-content-end ">
              <label className="me-2 align-self-center">Empleado</label>
                  <input
                      className="form-control"
                      value={empleado}
                      onChange={(e) => {
                      e.preventDefault();
                      setEmpleado(e.target.value);
                      }}
                      type="text"
                  />

            </div>

          </div>
        </div>
      </div>

      <div className="row mt-4 mx-auto">
      {
              listadoBonoEscolarDinamico.length > 0 
              && listadoBonoEscolarDinamico.map((item, index) => (

              <div className="card mb-3" key={index}>
                <div className="row g-0">
                  <div className="col-1 text-center mt-3 mb-3">
                    <p className="card-text fw-bold text-14">No. Empleado</p>
                    <p className="text-14">{item.empleadoId}</p>
                  </div>

                  <div className="col-2">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Nombre</p>
                      <p className="text-14">{item.nombredelEmpleado}</p>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Beneficiario</p>
                      <p className="text-14">{item.beneficiario}</p>
                    </div>
                  </div>

                
                  <div className="col-1">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Edad</p>
                      <p className="text-14">{item.edad}</p>
                    </div>
                  </div>

                  <div className="col-1">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Monto por mes</p>
                      <p className="text-14">{numbro(item.montoPorMes).formatCurrency(formatNumberRD)}</p>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Cantidad de meses</p>
                      <p className="text-14">{item.cantidadMeses}</p>
                    </div>
                  </div>

                  <div className="col-1">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Total</p>
                      <p className="text-14">{numbro(item.total).formatCurrency(formatNumberRD)}</p>
                    </div>
                  </div>

                  <div className={estadoPeriodo === 0 ? "col-1" : "col-2"}>
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Pago único</p>
                      {
                        item.pagoUnico === 1 &&
                          <DoneAllIcon />
                      }
                    </div>
                  </div>

                  <div className="col-1">
                    <div className='mt-2 mb-2 d-flex justify-content-center' >

                      {
                        estadoPeriodo === 0 && 
                          <>
                            <IconButtonText Icon={ModeEditOutlineIcon} func={openModalEdit} params={item} />
                            <IconButtonText Icon={DeleteForeverIcon} color={'#C72924'} func={deleteBonificacion} params={item.beneficiarioBonoEscolarId} />
                          </>
                      }

                    </div>
                  </div>

                </div>
              </div>
          ))
        }

      {
        listadoBonoEscolarDinamico.length > 0 &&
          <TablePagination
          component="div"
          count={listaBonoEscolarLength}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }

      </div>

      <EditarBonoEscolarEmpleadoModal 
        setOpen={setOpenModal}
        open={openModal}
        setBeneficiario={setBeneficiario}
        beneficiario={beneficiario}
      />

      {/* PANTALLA DE CARGA */}
      <Loading open={isLoading} />

    </Encabezado>
  )
}
