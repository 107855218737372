import React, { useState, useEffect } from "react";
import {
  FiltroResumenAsistencia,
  ResumenAsistenciaCard,
} from "../../../Components/Cards";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../Components/Modals/Loading/Loader";
import TablePagination from "@mui/material/TablePagination";

import {
  fetchResumenAsistencias,
  selectIsLoading,
  selectListadoPonches,
  fetchResumenAsistenciasExcel,
  selectIsSuccess,
  fetchCambiarSuccess
} from "../../../redux/Reportes/ResumenAsistenciasSlice";

import { removeAccents } from "../../../utils/removeAccents";
import "./styles/style.css";
import swal from "sweetalert";

export const ResumenAsistencia = () => {
  
  // ? USE-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const listaEmpleadosPonches = useSelector(selectListadoPonches);
  const isLoading = useSelector(selectIsLoading);
  const isSuccess = useSelector(selectIsSuccess);

  // ? HOOK-STATE
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [empleados, setEmpleados] = useState([]);
  const [skipRow, setSkipRow] = useState(0);
  const [cantidadEmpleados, setCantidadEmpleados] = useState(0);
  const [empleadosFiltros, setEmpleadosFiltros] = useState([]);
  const [value, setValue] = useState("");

  // ? HOOK-EFFECT
  useEffect(() => {
    if (cantidadEmpleados === 0) {
      setCantidadEmpleados(listaEmpleadosPonches.length);
    }

    listaEmpleados();
  }, [listaEmpleadosPonches, skipRow, rowsPerPage]);
  
  useEffect(() => {
    searchInputValue();
  }, [value]);

  // * USE-EFFECT PARA EL SWEET-ALERT.
  useEffect(() => {
  
    if(isSuccess === 'success'){
      swal("Exitoso!", "El reporte fue generado.", "success");
      dispatch(fetchCambiarSuccess());
    }
    else if(isSuccess === 'error'){
      swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
      dispatch(fetchCambiarSuccess());
    }

  }, [isSuccess])


  // * FUNCION QUE BUSCA LOS DATOS DEL FILTRO.
  const searchResumenAsistencia = (filtro) => {
    dispatch(fetchResumenAsistencias(filtro));
  };

  // * FUNCION QUE SOLICITA UN REPORTE DEL RESUMEN DE ASISTENCIA.
  const exportExcel = (filtro) => {
    dispatch(fetchResumenAsistenciasExcel(filtro));
  }

  // * FUNCION DE FLECHA QUE ACTUALIZARA LOS STATES PARA LUEGO
  // * SER UTILIZADOS EN EL ARREGLO.
  const handleChangePage = (event, newPage) => {
    setSkipRow(newPage * rowsPerPage);
    setPage(newPage);
  };

  // * FUNCION QUE ALMACENA EN EL STATE LA CANTIDAD DE REGISTRO POR PAGINA.
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSkipRow(0);
  };

  // * FUNCION QUE ACTUALIZA EL RANGO DE EMPLEADOS POR PAGINA.
  const listaEmpleados = () => {

    if (listaEmpleadosPonches.length > 0 && empleadosFiltros.length < 1) {
      setEmpleados(listaEmpleadosPonches.slice(skipRow, rowsPerPage + skipRow));
    } else {
      setEmpleados(empleadosFiltros.slice(skipRow, rowsPerPage + skipRow));
    }
  };

  // * FUNCION QUE REALIZA EL FILTRADO DEL VALOR DEL INPUT.
  const searchInputValue = () => {
   
    if (value.length > 0) {
      // * REINICIAR PAGINACION.
      setPage(0);
      setSkipRow(0);

      // * LISTADO DE EMPLEADOS FILTRADOS.
      const listadoFiltro = listaEmpleadosPonches.filter((x) =>
        removeAccents(x.nombreLabel).toLowerCase().includes(value.toLowerCase())
      );

      // * ASIGNANDO LA NUEVA CANTIDAD DE EMPLEADOS SEGUN EL FILTRADO.
      setCantidadEmpleados(listadoFiltro.length);

      // * ALMACENAMIENTO DEL FILTRADO EN UN STATE.
      setEmpleadosFiltros(listadoFiltro);

      // * LLAMANDO LA FUNCION PARA MOSTRAR LOS NUEVOS VALORES DEL ARRAY.
      setEmpleados(listadoFiltro.slice(skipRow, rowsPerPage + skipRow));
    } else if (value.length === 0) {
      // * REINICIAR PAGINACION.
      setPage(0);
      setSkipRow(0);

      // * ASIGNANDO LA NUEVA CANTIDAD DE EMPLEADOS SEGUN EL FILTRADO.
      setCantidadEmpleados(listaEmpleadosPonches.length);

      // * ALMACENAMIENTO DEL FILTRADO EN UN STATE.
      setEmpleadosFiltros([]);

      setEmpleados(listaEmpleadosPonches.slice(skipRow, rowsPerPage + skipRow));
    }
  };

  // * VALIDACION QUE EVALUE QUE LA CANTIDAD DE EMPLEDOS SEA MAYOR A CERO PARA MOSTRAR LAS VENTANAS CORRESPONDIENTES.
  const validacionExistenciaEmpleados = empleados.length > 0 ? true : false;

  return (
    <div className="container-fluid main">
      <div className="row mt-2">
        <div className="col-md-12">
          <div>
            <h4 className="pt-2 ps-2">RESUMEN DE ASISTENCIA</h4>
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <FiltroResumenAsistencia
              searchResumenAsistencia={searchResumenAsistencia}
              setCantidadEmpleados={setCantidadEmpleados}
              cantidadEmpleados={listaEmpleadosPonches.length}
              setValue={setValue}
              value={value}
              setEmpleados={setEmpleados}
              setEmpleadosFiltros={setEmpleadosFiltros}
              exportExcel={exportExcel}
            />
          </div>
        </div>

        <div className="row mt-4">
          {validacionExistenciaEmpleados > 0 && (
            <div className="col">
              <ResumenAsistenciaCard empleados={empleados} />
            </div>
          )}
          {empleados.length <= 0 && listaEmpleadosPonches.length > 0 && (
            <div className="col text-center">
              <h2>No se encontraron los resultados de la búsqueda.</h2>
            </div>
          )}
        </div>
      </div>

      <div className="row mt-4">
        {validacionExistenciaEmpleados > 0 && (
          <TablePagination
            component="div"
            count={cantidadEmpleados}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </div>

      {/* PANTALLA DE CARGA */}
      <Loading open={isLoading} />
    </div>
  );
};
