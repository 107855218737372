import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { endpoints } from "../config";
import { messageFrontEnd } from "../../utils/mensajeErrorCliente";
import moment from "moment";

const initialState = {
    isLoading: false,
    isSuccess: 'idle',
    message: "",
    validacionAplicaSubsidioComida: false,
    empleadoData: {},
    semanaAlmuerzoDia: {},
    diasAlmuerzo: [],
    categoriasAlmuerzo: [],
    categoriasAlmuerzoSemanaActiva: [],
    menuComidaEmpleadoSemana: [],
    semanasAlmuerzos: [],
    comidas: []
}

// * LIMPIAR SUCCESS
export const fetchCambiarSuccess = createAsyncThunk("cafeteria/fetchCambiarSuccess",
    async () => {
      return null;
    }
);  

export const fetchBuscarEmpleado = createAsyncThunk("cafeteria/fetchBuscarEmpleado", 

    async (data) => {

        const {numeroEmpleado, cedula} = data;

        var resultado = await axiosApiInstance.get(`${endpoints.cafeteria.buscarEmpleado}/?codigoEmpleado=${numeroEmpleado}&cedula=${cedula}`);
        return resultado.data;
    }
)

export const fetchAgregarComidaEmpleado = createAsyncThunk("cafeteria/fetchAgregarComidaEmpleado", 

    async (empleado) => {

        var resultado = await axiosApiInstance.post(endpoints.cafeteria.agregarComida, empleado);
        return resultado.data;
    }
);

// * GENERAR EXCEL DE HISTORICO DE CAFETERIA
export const fetchGenerarReporteHistoricoCafeteria = createAsyncThunk(
    "cafeteria/fetchGenerarReporteHistoricoCafeteria",
    async (data) => {

      const { fechaDesde, fechaHasta } = data;

      const fechaDesdeParse = moment(fechaDesde).format('yyyy-MM-DD');
      const fechaHastaParse = moment(fechaHasta).format('yyyy-MM-DD');

      const result = await axiosApiInstance({
        url: `${
          endpoints.cafeteria.generarReporteHistoricoCafeteria
        }?desde=${fechaDesdeParse}&hasta=${fechaHastaParse}`, //your url
  
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Reporte Histórico Cafetería (${fechaDesdeParse} - ${fechaHastaParse}).xls`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  
      return result;
    }
);

// * GENERAR EXCEL DE HISTORICO DE CAFETERIA
export const fetchGenerarReporteEstadisticaCafeteria = createAsyncThunk(
    "cafeteria/fetchGenerarReporteEstadisticaCafeteria",
    async (data) => {

      const { fechaDesde, fechaHasta } = data;

      const fechaDesdeParse = moment(fechaDesde).format('yyyy-MM-DD');
      const fechaHastaParse = moment(fechaHasta).format('yyyy-MM-DD');

      const result = await axiosApiInstance({
        url: `${
          endpoints.cafeteria.generarReporteEstadisticaCafeteria
        }?desde=${fechaDesdeParse}&hasta=${fechaHastaParse}`, //your url
  
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Reporte Estadística Cafetería (${fechaDesdeParse} - ${fechaHastaParse}).xls`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  
      return result;
    }
);

// * GENERAR EXCEL DE HISTORICO DE CAFETERIA
export const fetchGenerarReporteCafeteriaSemanal = createAsyncThunk(
    "cafeteria/fetchGenerarReporteCafeteriaSemanal",
    async ({semanaId}) => {

      const result = await axiosApiInstance({
        url: `${
          endpoints.cafeteria.generarReporteCafeteriaSemanal
        }?semanaId=${semanaId}`, //your url
  
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Reporte de Cafetería Semanal.xls`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  
      return result;
    }
);

// * GENERAR PDF DE LABELS DE CAFETERIA POR SEMANA
export const fetchGenerarReporteLabelsSemanalCafeteria = createAsyncThunk(
    "cafeteria/fetchGenerarReporteLabelsSemanalCafeteria",
    async ({semanaId, semanaLabel}) => {

      const result = await axiosApiInstance({
        url: `${
          endpoints.cafeteria.generarReporteLabelsSemanalCafeteria
        }?semanaId=${semanaId}`, //your url
  
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
  
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `Labels de Cafetería de la ${semanaLabel}.pdf`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
  
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  
      return result;
    }
);


export const fetchGetSemanaAlmuerzoActiva = createAsyncThunk("cafeteria/fetchGetSemanaAlmuerzoActiva", 

  async () => {
    var resultado = await axiosApiInstance.get(endpoints.cafeteria.getSemanaAlmuerzoActiva);
    return resultado.data;
  }
)

export const fetchGetDiasAlmuerzo = createAsyncThunk("cafeteria/fetchGetDiasAlmuerzo", 

  async () => {
    var resultado = await axiosApiInstance.get(endpoints.cafeteria.getDiaAlmuerzo);
    return resultado.data;
  }
)

export const fetchGetCategoriasAlmuerzo = createAsyncThunk("cafeteria/fetchGetCategoriasAlmuerzo", 

  async () => {
    var resultado = await axiosApiInstance.get(endpoints.cafeteria.getCategoriasAlmuerzo);
    return resultado.data;
  }
)

export const fetchGetCategoriasAlmuerzoSemanaActiva = createAsyncThunk("cafeteria/fetchGetCategoriasAlmuerzoSemanaActiva", 

  async () => {
    var resultado = await axiosApiInstance.get(endpoints.cafeteria.getCategoriasAlmuerzoSemanaActiva);
    return resultado.data;
  }
)

export const fetchGetMenuComidaEmpleadoSemana = createAsyncThunk("cafeteria/fetchGetMenuComidaEmpleadoSemana", 

  async ({empleadoId}) => {
    var resultado = await axiosApiInstance.get(`${endpoints.cafeteria.getMenuComidaEmpleadoSemana}?empleadoId=${empleadoId}`);
    return resultado.data;
  }
)

export const fetchSaveMenuComidaEmpleadoSemana = createAsyncThunk("cafeteria/fetchSaveMenuComidaEmpleadoSemana", 

    async ({empleadoId, data}) => {
      var resultado = await axiosApiInstance.post(`${endpoints.cafeteria.fetchSaveMenuComidaEmpleadoSemana}?empleadoId=${empleadoId}`, data);
      return resultado.data;
    }
  )

  export const fetchSaveMenuComidaRRHH = createAsyncThunk("cafeteria/fetchSaveMenuComidaRRHH", 

    async (data) => {
      var resultado = await axiosApiInstance.post(endpoints.cafeteria.fetchSaveMenuComidaRRHH, data);
      return resultado.data;
    }
  )

  export const fetchValidacionSubsidioComidaEmpleadoAplica = createAsyncThunk("cafeteria/fetchValidacionSubsidioComidaEmpleadoAplica", 

    async ({empleadoId}) => {
      var resultado = await axiosApiInstance.get(`${endpoints.cafeteria.fetchValidacionSubsidioComidaEmpleadoAplica}?empleadoId=${empleadoId}`);
      return resultado.data;
    }
  )

  export const fetchGetSemanasAlmuerzo = createAsyncThunk("cafeteria/fetchGetSemanasAlmuerzo", 

    async () => {
      var resultado = await axiosApiInstance.get(endpoints.cafeteria.getSemanasAlmuerzo);
      return resultado.data;
    }
  )
  
  export const fetchGetComidas = createAsyncThunk("cafeteria/fetchGetComidas", 

    async () => {
      var resultado = await axiosApiInstance.get(endpoints.cafeteria.getComidas);
      return resultado.data;
    }
  )

// * LIMPIAR EL STATE.
export const fetchLimpiarStateEmpleado = createAsyncThunk("cafeteria/fetchLimpiarStateEmpleado",
    async () => {
      return null;
    }
); 

export const CafeteriaSlice = createSlice({
    name:"cafeteria",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchCambiarSuccess.fulfilled, (state) => {
            state.isSuccess = "idle";
            state.message = '';
        })
        .addCase(fetchLimpiarStateEmpleado.fulfilled, (state) => {
            state.empleadoData = {};
        })
        .addCase(fetchBuscarEmpleado.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchBuscarEmpleado.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.empleadoData = action.payload.data ?? {};
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchBuscarEmpleado.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.empleadoData = {};
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGetSemanaAlmuerzoActiva.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchGetSemanaAlmuerzoActiva.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.semanaAlmuerzoDia = action.payload.data ?? {};
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchGetSemanaAlmuerzoActiva.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGetDiasAlmuerzo.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchGetDiasAlmuerzo.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.diasAlmuerzo = action.payload.data ?? {};
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchGetDiasAlmuerzo.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGetCategoriasAlmuerzo.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchGetCategoriasAlmuerzo.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.categoriasAlmuerzo = action.payload.data ?? [];
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchGetCategoriasAlmuerzo.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGetCategoriasAlmuerzoSemanaActiva.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchGetCategoriasAlmuerzoSemanaActiva.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.categoriasAlmuerzoSemanaActiva = action.payload.data ?? [];
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchGetCategoriasAlmuerzoSemanaActiva.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGetMenuComidaEmpleadoSemana.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchGetMenuComidaEmpleadoSemana.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.menuComidaEmpleadoSemana = action.payload.data ?? [];
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchGetMenuComidaEmpleadoSemana.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchSaveMenuComidaEmpleadoSemana.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchSaveMenuComidaEmpleadoSemana.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.message = action.payload.message;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchSaveMenuComidaEmpleadoSemana.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchSaveMenuComidaRRHH.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchSaveMenuComidaRRHH.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.message = action.payload.message;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }

            state.isLoading = false;
        })
        .addCase(fetchSaveMenuComidaRRHH.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchAgregarComidaEmpleado.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchAgregarComidaEmpleado.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } 
            else {
                state.isSuccess = 'error'; 
            }

            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchAgregarComidaEmpleado.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchValidacionSubsidioComidaEmpleadoAplica.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchValidacionSubsidioComidaEmpleadoAplica.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
            } 
            else {
                state.isSuccess = 'error'; 
            }

            state.validacionAplicaSubsidioComida = action.payload.data;
            state.message = action.payload.message;
            state.isLoading = false;
        })
        .addCase(fetchValidacionSubsidioComidaEmpleadoAplica.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGetSemanasAlmuerzo.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchGetSemanasAlmuerzo.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.semanasAlmuerzos = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }
            state.isLoading = false;
        })
        .addCase(fetchGetSemanasAlmuerzo.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGetComidas.pending, (state) => {
            state.isSuccess = "idle"
            state.isLoading = true;
        })
        .addCase(fetchGetComidas.fulfilled, (state, action) => {  
            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.comidas = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
            }
            state.isLoading = false;
        })
        .addCase(fetchGetComidas.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = messageFrontEnd();
            console.error(action.error.message);
        })
        .addCase(fetchGenerarReporteHistoricoCafeteria.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
        .addCase(fetchGenerarReporteHistoricoCafeteria.fulfilled, (state, action) => {
            state.isSuccess = "success";
            state.message = "El reporte fue generado";
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteHistoricoCafeteria.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = action.error.message;
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteCafeteriaSemanal.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
        .addCase(fetchGenerarReporteCafeteriaSemanal.fulfilled, (state, action) => {
            state.isSuccess = "success";
            state.message = "El reporte fue generado";
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteCafeteriaSemanal.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = action.error.message;
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteLabelsSemanalCafeteria.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
        .addCase(fetchGenerarReporteLabelsSemanalCafeteria.fulfilled, (state, action) => {
            state.isSuccess = "success";
            state.message = "El reporte fue generado";
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteLabelsSemanalCafeteria.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = action.error.message;
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteEstadisticaCafeteria.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
        .addCase(fetchGenerarReporteEstadisticaCafeteria.fulfilled, (state, action) => {
            state.isSuccess = "success";
            state.message = "El reporte fue generado";
            state.isLoading = false;
        })
        .addCase(fetchGenerarReporteEstadisticaCafeteria.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = action.error.message;
            state.isLoading = false;
        })
        ;
       
    }
})

export const selectIsLoading = (state) => state.cafeteria.isLoading;
export const selectIsSuccess = (state) => state.cafeteria.isSuccess;
export const selectMessage = (state) => state.cafeteria.message;
export const selectEmpleado = (state) => state.cafeteria.empleadoData;
export const selectSemanaAlmuerzoActiva = (state) => state.cafeteria.semanaAlmuerzoDia;
export const selectDiasAlmuerzo = (state) => state.cafeteria.diasAlmuerzo;
export const selectCategoriasAlmuerzo = (state) => state.cafeteria.categoriasAlmuerzo;
export const selectCategoriasAlmuerzoSemanaActiva = (state) => state.cafeteria.categoriasAlmuerzoSemanaActiva;
export const selectMenuComidaEmpleadoSemana = (state) => state.cafeteria.menuComidaEmpleadoSemana;
export const selectValidacionSubsidioComidaEmpleadoAplica = (state) => state.cafeteria.validacionAplicaSubsidioComida;
export const selectSemanasAlmuerzo = (state) => state.cafeteria.semanasAlmuerzos;
export const selectComidas = (state) => state.cafeteria.comidas;

export const {} = CafeteriaSlice.actions;
export default CafeteriaSlice.reducer;