import React, 
{ 
  useContext, 
  useEffect, 
  useState 
} from 'react';

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from 'react-router-dom';
import { PermissionContext } from '../../context/PermissionContext';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import { 
   
} from '@mui/material';

import moment from 'moment/moment';
import Loading from "../../Components/Modals/Loading/Loader";

import CircleIcon from '@mui/icons-material/Circle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { PiPasswordFill } from "react-icons/pi";

import {
  Settings,
  Logout
} from '@mui/icons-material';

import { 
  ToastContainer, 
  toast 
} from 'react-toastify';

import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  ListItemText, 
  Typography,
  Avatar,
  ListItemIcon,
  Divider,
  Menu,
  MenuItem,
  Fade,
  Badge

} from '@mui/material';

import { endpoints } from '../../redux/config';

import { 
  fetchNotificacionesEmpleado, 
  fetchMarcarComoLeido,
  selectNotificacionesEmpleado 
} from '../../redux/Notificaciones/Notificaciones';

import { 
  fetchLogout,
  fetchCambiarSuccess,
  selectIsLoading,
  selectUserData,
  selectMessage,
  selectIsSuccess
} from "../../redux/Login/LoginSlice";

import { fetchGetEmpleadosSorteo } from '../../redux/Sorteo/SorteoSlice';

import { CambiarContraseñaModal } from './Modal/CambiarContraseñaModal';

import swal from 'sweetalert';

import './NavMenu.css';
import 'react-toastify/dist/ReactToastify.css';


export default function NavMenu() {

  // ? HOOK-NAVIGATE
  const navigate = useNavigate();

  // ? USE-STATE PARA ABRIR EL MENU DESPLEGABLE DE LAS NOTIFICACIONES.
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  // ? HOOK-STATE PARA ABRIR MODAL PARA CAMBIAR CONTRASEÑA
  const [openPassword, setOpenPassword] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [countChangePasswordTries, setCountChangePasswordTries] = useState(0);

  // ? HOOK-STATE PARA GUARDAR LA CONEXION DE SIGNAL-R
  const [connection, setConnection] = useState();

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? USE-SELECTOR
  const usuario = useSelector(selectUserData);
  const isLoading = useSelector(selectIsLoading);
  const notificaciones = useSelector(selectNotificacionesEmpleado);
  const message = useSelector(selectMessage);
  const isSuccess = useSelector(selectIsSuccess);

  // * EMAIL DEL USUARIO
  const email = usuario?.Email;

  // * EXTRAYENDO LOS DATOS DEL CONTEXT.
  const value = useContext(PermissionContext);

  // * DESESTRUCTURACION DE LOS DATOS.
  const { clearStoragePermisos } = value.value;

  // * FUNCION QUE CIERRA LA SESION.
  const logout = () => {

    // * LIMPIANDO EL STORAGE
    clearStoragePermisos();
    dispatch(fetchLogout());

    // * ELIMINANDO LA CONEXION DE SIGNAL-R
    connection.invoke("Logout", email).catch( err => console.error)
  };

  // * CERRANDO LA SESION SI EL USUARIO POSEE LA PRIMERA IMPLEMENTACION DE LOS PERMISOS
  // * DE USUARIO.

  useEffect(() => {
    if(window.localStorage.getItem("ListaPermisos") !== null){
      logout()
    }
  }, [])

  // * FUNCION PARA REFRESCAR LISTA
  const refreshList = () =>  dispatch(fetchNotificacionesEmpleado(email))


  // ? USE-EFFECT PARA INICIALIZAR LA CONEXION
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(endpoints.notificaciones.hub)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);


  // ? USE-EFFECT PARA INVOCAR Y RECIBIR DESDE EL WEB-SOCKET.
  useEffect(() => {
    if (connection) {

      // * CONSULTANDO NOTIFICACIONES SEGUN EL EMAIL.
      refreshList();

      connection
        .start()
        .then(() => {
          // * GUARDANDO LA CONEXION DEL USUARIO EN LA API.
          connection.invoke("SaveUser", email).catch( err => console.error)
        })
        .catch((error) => console.error(error));

        // * SI SE RECIBE UNA NOTIFICACION SEGUN LA CONEXION PUES ACTUALIZARA
        // * LAS NOTIFICACIONES.
        connection.on("ReceivedPersonalNotification", async function(data){
          refreshList()
          
          // * DESESTRUCTURACION DE LOS DATOS.
          const { titulo, estado } = data;

          if(estado === 1){
            notificacionGeneral(titulo)
          } 
          else if(estado === 2){
            notificacionPermisoAprobado(titulo)
          }
          else if(estado === 3){
            notificacionPermisoRechazado(titulo)
          }

        });

        // * SI EL USUARIO LEYÓ UNA NOTIFICACION PUES SE ACTUALIZA LA LISTA.
        connection.on("ReadNotificationUpdate", async function(){
          refreshList()
        });

        connection.on("RefreshListRifa", async function(){
          dispatch(fetchGetEmpleadosSorteo());
        });
    }

  }, [connection]);
  
  // ? HOOK-EFFECT PARA EL MANEJO DE MENSAJES SUCCESS AL MOMENTO DE CAMBIAR LA CONTRASEÑA.
  useEffect(() => {
    
    if(isSuccess === 'success' && message.length > 0){
      swal("Exitoso!", message, "success");
      dispatch(fetchCambiarSuccess());
      setResetForm(true);

      // * RESET CANTIDAD DE INTENTOS DE CAMBIOS DE CONTRASEÑA.
      setCountChangePasswordTries(0);
    }
    else if(isSuccess === 'error'){
      swal("Error", message, "error");
      dispatch(fetchCambiarSuccess());

      // * ALMACENAR NUMEROS DE INTENTOS, SI ES IGUAL A 2 PUES LA SESION CIERRA EN AUTOMATICO.
      setCountChangePasswordTries(countChangePasswordTries + 1);
    }

  }, [isSuccess])

  // ? HOOK-EFFECT PARA EL CIERRE DE LA SESION SI EL USUARIO INTENTA CAMBIAR LA CONTRASEÑA 2 VECES.
  useEffect(() => {
    if(countChangePasswordTries === 2){

      // * RESET CANTIDAD DE INTENTOS DE CAMBIOS DE CONTRASEÑA.
      setCountChangePasswordTries(0);

      // * MENSAJE DE ERROR.
      swal("Error", "Has intentado cambiar la contraseña varias veces. Por favor, vuelve a iniciar sesión nuevamente.", "error");

      // * LIMPIAR FORM DEL CAMBIO DE CONTRASE
      setResetForm(true);

      // * CERRANDO SESION.
      logout();
    }
  }, [countChangePasswordTries])
  

  // * FUNCIONES PARA ABRIR Y CERRAR NOTIFICACIONES.
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  // * NOTIFICACION GENERAL
  const notificacionGeneral = (text) => toast.info(<CustomToast text={text} />, {
    icon: false,
    progressStyle: {
      backgroundColor: 'blue'
    }
  });

  // * NOTIFICACION DE PERMISO APROBADO
  const notificacionPermisoAprobado = (text) => toast.success(text);

  // * NOTIFICACION DE PERMISO RECHAZADO
  const notificacionPermisoRechazado = (text) => toast.error(text);

  const CustomToast = ({text}) => (
    <div>
     <p className="text">¡Nueva Notificación!</p>
     <label>{text}</label>
    </div>
  );

  const navigateTo = (item) => {
    navigate(item.ruta);
   
    if(item.leido === 0){
      dispatch(fetchMarcarComoLeido(item.notificacionId))
    }
  }

  // * VALIDAR DE QUE EL USUARIO SEA EXTERNO PARA HABILITAR LA OPCION DE CAMBIAR CONTRASEÑA.
  const validacionUsuarioExterno = usuario?.UsuarioExterno === "True" ? true : false ?? false;  

  return (

    <AppBar position="static" className="bg-primary-mem">
        <Toolbar>

          <Typography
          variant="h6"
          noWrap
          component={Link} 
          to="/" 
          sx={{
            flexGrow: 1,
            mr: 2,
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'white',
            textDecoration: 'none',
            '&:hover': {
            color: 'white', 
            textDecoration: 'none', 
          },
          }}
          fontSize={20}
          color='white'
          >
            SIREH
          </Typography>

          <IconButton
          sx={{mr: 4}}
          style={anchorEl === null ? { ":hover": { backgroundColor: '#008EC7'}} : {backgroundColor: '#008EC7'}}
          id="btn-notificacion"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          >
            <Badge badgeContent={notificaciones.filter(x => x.leido === 0).length ?? 0} color='error' max={10}>
              <NotificationsIcon style={{color: '#FFFF'}} />
            </Badge>
          </IconButton>

          <Typography fontSize={16} color='white'>{usuario.Nombre}</Typography>

          <IconButton 
          id='btn-perfil'
          onClick={handleClick2} 
          style={anchorEl2 === null ? { ":hover": { backgroundColor: '#008EC7'}} : {backgroundColor: '#008EC7'}}
          >
            <Avatar sx={{ width: 34, height: 34 }}>{Object.keys(usuario).length > 0 ? usuario.Nombre[0] : ""}</Avatar>
          </IconButton>

        
          <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          TransitionComponent={Fade}
          PaperProps={{
            elevation: 0,
            sx: { 
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 4.5,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 16,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
            style: {
              maxHeight: '40vh', // Altura máxima en viewport height para limitar la altura del menú
              width: '25%',    
              overflowY: 'auto', // Habilita el scroll vertical cuando se excede la altura máxima
            },
          }}
          >
            {
              notificaciones.length > 0 ? 
              notificaciones.map((item, index) => (
                <MenuItem
                  onClickCapture={() => navigateTo(item)}
                  style={item.leido === 0 ? { backgroundColor: '#EFEFEF', whiteSpace: 'normal' } : { whiteSpace: 'normal' }}
                  onClick={handleClose}
                  key={index}
                  divider={(index + 1) < notificaciones.length}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: { fontSize: 14 } }}
                    primary={item.titulo}
                    secondary={moment(item.fecha).calendar()}
                  />
                  <CircleIcon style={{ fontSize: 10, visibility: item.leido === 1 && 'hidden' }} color="info" />
                </MenuItem>
              ))
              : 
              <MenuItem>
                <Typography fontSize={13}>No se encontraron notificaciones</Typography>
              </MenuItem>
            }
          </Menu>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open2}
            onClose={handleClose2}
            onClick={handleClose2}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 5.2,
                ml: -3.2,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.6,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <MenuItem onClick={handleClose}>
              <Avatar /> Mi Cuenta
            </MenuItem>
            <Divider />
            <MenuItem hidden={!validacionUsuarioExterno} onClick={() => setOpenPassword(true)}>
              <ListItemIcon>
                <PiPasswordFill fontSize="20px" />
              </ListItemIcon>
              Cambiar contraseña
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Configuración
            </MenuItem>
            <MenuItem onClick={() => logout()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Cerrar Sesión
            </MenuItem>
          </Menu>

        </Toolbar>


      <ToastContainer 
        position="top-center"
      /> 

      <CambiarContraseñaModal
      open={openPassword}
      setOpen={setOpenPassword}
      usuario={usuario}
      resetForm={resetForm}
      setResetForm={setResetForm}
      />

      {/* PANTALLA DE CARGA */}
      <Loading open={isLoading} />
    </AppBar>
  );
}

  
