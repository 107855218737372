import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import Loading from '../../Components/Modals/Loading/Loader';
import { FaFileExcel } from 'react-icons/fa';
import { FaFilePdf } from "react-icons/fa6";
import { Controller, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import Select from 'react-select';
import swal from 'sweetalert';

import {
    fetchGetSemanasAlmuerzo,
    fetchGenerarReporteCafeteriaSemanal,
    fetchCambiarSuccess,
    fetchGenerarReporteLabelsSemanalCafeteria,
    selectIsLoading,
    selectMessage,
    selectSemanasAlmuerzo, 
    selectIsSuccess
} from '../../redux/Cafeteria/CafeteriaSlice';

export const ReporteCafeteriaSemanal = () => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const semanas = useSelector(selectSemanasAlmuerzo);
    const isLoading = useSelector(selectIsLoading);
    const isSuccess = useSelector(selectIsSuccess);
    const message = useSelector(selectMessage);

    // ? HOOK-FORM
    const { 
        control,
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            semanaId: 0
        }
    });

    // ? HOOK-EFFECT
    useEffect(() => {
        dispatch(fetchGetSemanasAlmuerzo());
    }, [])
    
    // ? USE-EFFECT PARA EL SWEET-ALERT.
    useEffect(() => {
    
        if(isSuccess === 'success' && message){
            swal("Exitoso!", "El reporte fue generado.", "success");
            dispatch(fetchCambiarSuccess());
            
            // * REESTABLECER VALORES PREDETERMINADOS.
            defaultValueSemanaId();
        }
        else if(isSuccess === 'error'){
            swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
            dispatch(fetchCambiarSuccess());
        }

    }, [isSuccess])

    useEffect(() => {
        
        if(semanas.length > 0){
            defaultValueSemanaId();
        }

    }, [semanas])

    // * FUNCION PARA SOLICITAR EL REPORTE DEL MENU POR AREAS..
    const getReportCafeteriaAreas = () => {

        const { semanaId } = watch();

        dispatch(fetchGenerarReporteCafeteriaSemanal({semanaId}));
    }

    // * FUNCION PARA SOLICITAR EL REPORTE DE IMPRESION DE LABELS DE EMPLEADOS.
    const getReportReportLabels = () => {

        const { semanaId } = watch();
        const { semanaLabel = ""} = semanas.find(x => x.id === semanaId);

        dispatch(fetchGenerarReporteLabelsSemanalCafeteria({semanaId, semanaLabel}));
    }

    // * FUNCION PARA AGREGAR VALOR POR DEFECTO
    const defaultValueSemanaId = () => {

        const maxSemanaId = semanas.reduce((max, current) => {
            return current.id > max.id ? current : max;
        }, { id: -Infinity })?.id ?? 0; // Inicializamos con un objeto con id muy bajo
        
        setValue('semanaId', maxSemanaId);
    }

  return (
    <Encabezado title='Reporte de Cafetería Semanal'>
        <div className='row'>
            <div className='col-6'>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">

                            <Typography className='fw-bold' fontSize={14}>Semana</Typography>
                            <Controller
                            control={control}
                            name="semanaId"
                            {...register("semanaId", { required: "Este campo es requerido"})}
                            render={({ field }) => (
                                <Select
                                classNamePrefix="select"
                                isSearchable={true}
                                placeholder="Seleccionar Semana"
                                options={semanas}
                                getOptionLabel={(option) => option.semanaLabel}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => field.onChange(e.id)}
                                value={semanas.find(x => x.id === field.value)}
                                />
                            )}
                            />

                            <p className='text-danger' hidden={!errors.semanaId?.message}>{errors.semanaId?.message}</p>
                            
                            <div className='mt-4 d-flex justify-content-between'>
                                <div>
                                    <Typography className='fw-bold' fontSize={14} mb={1}>Reporte Cafetería por Áreas</Typography>
                                    <button type='button' id="btn-generar-reporte" className="btn btn-success" onClick={() => getReportCafeteriaAreas()}>Generar EXCEL <FaFileExcel style={{marginTop: -3}} fontSize={20} /></button>
                                </div>
                                <div>
                                    <Typography className='fw-bold' fontSize={14} mb={1}>Impresión de Etiquetas para Empleados</Typography>
                                    <div className='d-flex justify-content-end'>
                                        <button type='button' id="btn-generar-reporte-pdf" className="btn btn-danger" onClick={() => getReportReportLabels()}>Generar PDF <FaFilePdf style={{marginTop: -3, marginLeft: 2}} fontSize={20} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* PANTALLA DE CARGA */}
        <Loading open={isLoading} />

    </Encabezado>
  )
}
