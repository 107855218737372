import React from "react";
import { Route, Routes, Navigate } from "react-router";
import { 
  DigitarEmpleadoMenuComida, 
  ReporteCafeteriaSemanal,
  RegistrarAlmuerzoSemanalRRHH
} from "../pages/Cafeteria";

import { RequiredPermission } from "../authentication/RequiredPermission";

export const RutasCafeteria = () => {

  return (
    <Routes>

      <Route path="/registrarMenu" element={<DigitarEmpleadoMenuComida />} />

      <Route path="/registro/menuSemanal" element={<RegistrarAlmuerzoSemanalRRHH />} />

      <Route element={<RequiredPermission permission='ROLE_GENERAR_REPORTE_CAFETERIA' />}>
        {/* <Route path="/reporte/historico" element={<ReporteHistorico />} />
        <Route path="/reporte/estadistica" element={<ReporteEstadistica />} /> */}
        <Route path="/reporte/semanal" element={<ReporteCafeteriaSemanal />} />
      </Route>

      {/* <Route element={<RequiredPermission permission='ROLE_DIGITAR_SUBSIDIO_COMIDA_CAFETERIA' />}>
        <Route index element={<RacionComida />} />
      </Route> */}

      {/* <Route element={<RequiredPermission permission='ROLE_ADMIN' />}>
        <Route index element={<RacionComida />} />
      </Route> */}

      {/* <Route element={<RequiredPermission permission='ROLE_ADMIN' />}>
        <Route path="/reporte/historico" element={<ReporteHistorico />} />
        <Route path="/reporte/estadistica" element={<ReporteEstadistica />} />
      </Route> */}

      <Route path='/*' element={<Navigate to="/" replace />} />

    </Routes>
  );
};
