import React from "react";
import { SignatureMaker } from "@docuseal/signature-maker-react";
import Modal from "@mui/material/Modal";
import { Box, Divider, Typography } from "@mui/material";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export const Firma = ({open, setOpen, setDataFirma, dataFirma, setValidateSendDataToApi}) => {

    // * FUNCION PARA GUARDAR 
    const handleSave = ({ base64 }) => {
        const url = `data:image/png;base64,${base64}`;

        setDataFirma({...dataFirma, file: url});
        setValidateSendDataToApi(true);
    };
    
    return (

        <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        >

            <Box sx={{ ...style}}>

                <div className="p-3">

                    <Typography variant="h5" align="center">CREAR FIRMA</Typography>
                    <Divider style={{color: 'black'}} />

                    <div style={{ width: 500, height: 400, marginTop: 40 }}>
                        <SignatureMaker
                            saveButtonText={"Guardar"}
                            undoButtonText="Atrás"
                            clearButtonText="Limpiar"
                            drawTypeButtonText="Dibujar"
                            textTypeButtonText="Escribir"
                            withUpload={false}
                            withColorSelect={false}
                            textInputPlaceholder="Escriba aquí su firma"
                            typeButtonsContainerClass="flex gap-2 mb-4 justify-center d-flex"
                            // drawTypeButtonClass="flex items center justify-center py-3 w-100 uppercase border-neutral-focus space-x-2 border rounded-3xl cursor-pointer hover:bg-neutral hover:text-white hover:font-semibold"
                            drawTypeButtonActiveClass="bg-primary text-white font-semibold"
                            // textTypeButtonClass="flex items center justify-center py-3 w-100 uppercase border-neutral-focus space-x-2 border rounded-3xl cursor-pointer hover:bg-neutral hover:text-white hover:font-semibold"
                            textTypeButtonActiveClass="bg-primary text-white font-semibold"
                            undoButtonClass="btn btn-outline btn-sm font-medium"
                            clearButtonClass="btn btn-outline btn-sm font-medium"
                            saveButtonClass="btn btn-primary-mem text-base w-full"
                            onSave={handleSave} // Manejador del botón de guardar
                            canvasProps={{
                                style: { backgroundColor: "transparent" }, // Asegurar fondo transparente
                            }}
                        />
                       
                    </div>
                </div>

                
                <div className="d-flex justify-content-center mt-3">
                    {/* <button
                    id="btn-guardar"
                    type="submit"
                    className="btn btn-primary-mem me-3"
                    >
                    <SaveIcon sx={{ marginTop: -0.5, marginRight: 0.5 }} />{" "}
                    Guardar
                    </button> */}

                    <button
                    id="btn-cancelar"
                    type="button"
                    className="btn btn-danger "
                    onClick={() => setOpen(false)}
                    >
                    <DoNotDisturbAltIcon
                        sx={{ marginTop: -0.5, marginRight: 0.5 }}
                    />
                    Cancelar
                    </button>
                </div>

            </Box>
        </Modal>
    );
};

export default Firma;
