import React, { useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { 
  Step, 
  StepButton, 
  Stepper
} from '@mui/material';

import { Encabezado } from '../../Components/Encabezado/Encabezado';
import { RegistroMenuComidaRRHHPage } from '../../Components/Cafetería/Page/RegistroMenuComidaRRHHPage';

import { 
  selectDiasAlmuerzo,
  selectComidas,
  selectCategoriasAlmuerzo,
  fetchGetCategoriasAlmuerzo,
  fetchGetDiasAlmuerzo,
  fetchGetComidas
} from '../../redux/Cafeteria/CafeteriaSlice';

export const RegistrarAlmuerzoSemanalRRHH = () => {

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-FORM
  const form = useForm({
    defaultValues: {
      categoriaId: 0,
      comidaId: 0
    }
  })

  // * DESESTRUCTURACION DE DATOS.
  const { setValue, watch } = form;

  // ? HOOK-SELECTOR
  const diasSemanas = useSelector(selectDiasAlmuerzo);
  const categoriasAlmuerzo = useSelector(selectCategoriasAlmuerzo);   
  const comidas = useSelector(selectComidas);

  // ? HOOK STATE PARA ALMACENAR LOS DIAS DE LUNES A VIERNES Y ADICIONAL EL RESUMEN DEL MENU SEMANAL.
  const [diasSemanaState, setDiasSemanaState] = useState([]);

  // ? HOOK-STATE PARA LA PAGINACION DE PAGINAS.
  const [stepPosition, setStepPosition] = useState({position: 0, diaId: 1}); 

  // ? HOOK-STATE
  const [comidasState, setComidasState] = useState([]);
  const [categoriasComidaState, setCategoriasComidaState] = useState([]);
  const [seleccionMenuComidaSemanalState, setSeleccionMenuComidaSemanalState] = useState([]);   
  const [addNewItemComida, setAddNewItemComida] = useState({});
   

  // ? HOOK-STATE PARA AÑADIR UN NUEVO DIA DE SEMANA QUE ES EL RESUMEN DE LA SELECCION.
  useEffect(() => {
    setDiasSemanaState([...diasSemanas, { id: 6, descripcion: "RESUMEN DE LA SELECCIÓN"}]);
  }, [diasSemanas])

  useEffect(() => {
    dispatch(fetchGetDiasAlmuerzo());
    dispatch(fetchGetCategoriasAlmuerzo());
    dispatch(fetchGetComidas());
  }, [])

  useEffect(() => {

    if(comidas.length > 0 && categoriasAlmuerzo.length > 0){

      let arrCategoriasAlmuerzo = [];

      for (let i = 0; i < categoriasAlmuerzo.length; i++) {
        const { descripcion, id} = categoriasAlmuerzo[i];

        const option = {
          label: descripcion,
          options: comidas.filter(x => x.categoriaAlmuerzoId === id)
        };

        arrCategoriasAlmuerzo.push(option);
      }

      setComidasState([...arrCategoriasAlmuerzo, {id: 0, descripcion: 'Seleccionar Comida'}]);
    }

  }, [comidas, categoriasAlmuerzo])


  useEffect(() => {
      
    if(Object.keys(addNewItemComida).length > 0){

      const { categoriaAlmuerzoId } = addNewItemComida;

      const { descripcion } = categoriasAlmuerzo.find(x => x.id === categoriaAlmuerzoId);

      const { options } = comidasState.find(x => x.label === descripcion);

      const optionsUpdate = [...options, addNewItemComida];

      const option = {
        label: descripcion,
        options: optionsUpdate
      };

      setComidasState((prevState) =>
        prevState.map((item) =>
          item.label === option.label // Usa una propiedad única para identificar el objeto
            ? { ...item, ...option } // Mezcla el objeto existente con los cambios de `option`
            : item // Si no coincide, devuelve el objeto original
        )
      );
    }

    setValue('comidaId', addNewItemComida.id)
  }, [addNewItemComida])

  // ? HOOK-EFFECT
  useEffect(() => {
    setCategoriasComidaState([...categoriasAlmuerzo, {id: 0, descripcion: 'Seleccionar Categoría'}])
  }, [categoriasAlmuerzo])

    // * COMPONENTE PARA ACTUALIZAR LA PAGINA SEGUN EL DIA ELEGIDO.
    const HandleSteps = ({ position : {diaId, position}}) => {      

      let children = <></>;
      
      children = 
      <RegistroMenuComidaRRHHPage 
      position={position}
      diaId={diaId}
      diasSemanas={diasSemanas}
      categoriasComidaState={categoriasComidaState}
      comidasState={comidasState}
      seleccionMenuComidaSemanalState={seleccionMenuComidaSemanalState}
      setSeleccionMenuComidaSemanalState={setSeleccionMenuComidaSemanalState}
      setAddNewItemComida={setAddNewItemComida}
      form={form}
      setStepPosition={setStepPosition}
      />

      return children;
    };
  
  return (
    <Encabezado title={"Selección de Comida"}>
      <Stepper alternativeLabel activeStep={stepPosition.position} sx={{ marginTop: 2 }}>
      
      {
        diasSemanaState.length > 0 &&
          diasSemanaState.map((item, index) =>
            <Step key={index}>
                <StepButton onClick={() => setStepPosition({position: index, diaId: item.id})}>
                    {item.descripcion}
                </StepButton>
            </Step>
          )
      }

      </Stepper>

      <div style={{ marginTop: '3%' }}>
        <HandleSteps position={stepPosition} />
      </div>

    </Encabezado>
  )
}
