import React, { useState, useEffect } from "react";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { FaEye } from "react-icons/fa";
import { IconButton }from "@mui/material";
import { Typography } from "@mui/material";
import { useParams } from 'react-router';
import { EvaluacionNotificacionModal, EvaluacionDetalleModal  } from "../../../Components/EvaluacionDesempeño/Modal";
import { BarraOpciones } from "../../../Components/EvaluacionDesempeño/Barra/BarraOpciones";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from '@mui/material/TablePagination';
import { removeAccents } from '../../../utils/removeAccents';
import { PermissionData } from '../../../context/Component/PermissionData';
import Loading from "../../../Components/Modals/Loading/Loader";
import { ProgressValueComponent } from "../../../Components/ProgressValue/ProgressValueComponent";
import swal from "sweetalert";
import { 
  fetchListaEvaluacionesPorAno, 
  fetchEvaluacionEliminar, 
  fetchObtenerEstadoPeriodo,
  fetchEvaluacionNotificacionesAll,
  fetchReabrirEvaluacion,
  fetchCambiarSuccess,
  fetchGenerarEvaluacionEmpleadoPDF,
  selectListaEvaluacionesPorAno, 
  selectEstadoPeriodo,
  selectIsLoading, 
  selectIsSuccess,
  selectMessage
  } from '../../../redux/Evaluaciones/EvaluacionesSlice';

  
import { 
  calcGrupoOcupacionalCompetencias, 
  calcGrupoOcupacionalCompetenciasComportamientos2024,
  calcTotalEvaluaciones2023
} from "../functions/evaluacionesFunciones";
import { Encabezado } from "../../../Components/Encabezado/Encabezado";

export const EvaluacionDesempeñoDetalle = () => {

  /* 

  * **************************************************************** *
  * *****************- ESTADOS DE UNA EVALUACION: -***************** *
  * **************************************************************** *
  *
  * 0: DISPONIBLE PARA EVALUAR.
  * 1: EVALUACION CERRADA INCLUYENDO EL PERIODO
  * 2: ACUERDOS DE DESEMPEÑOS DIGITADOS (APROBADO POR EL EVALUADOR)
  *
  * *************************************************************** *
  * *************************************************************** *
  */

  /* 
  * ************************************ *
  * *****- ESTADOS DE UN PERIODO: -***** *
  * ************************************ *
  * 
  * 0: PERIODO ABIERTO
  * 1: PERIODO CERRADO
  *
  * ************************************ *
  * ************************************ *
  */

  
  // * PERMISOS DEL USUARIO.
  const permisosState = PermissionData();

  // * PARAMETRO A TRAVES DE LA RUTA.
  const { ano } = useParams();

  // ? HOOK DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const listaEvaluacionesPorAno = useSelector(selectListaEvaluacionesPorAno);
  const isLoading = useSelector(selectIsLoading);
  const estadoPeriodo = useSelector(selectEstadoPeriodo);
  const isSuccess = useSelector(selectIsSuccess);
  const isMessage = useSelector(selectMessage);

  // ? HOOK NAVEGACION.
  const navigate = useNavigate();

  // ? HOOK-STATE PARA MODAL.
  const [open, setOpen] = useState(false);
  const [openN, setOpenN] = useState(false);
  const [tipoAccion, setTipoAccion] = useState(0);
  const [evaluado, setEvaluado] = useState({});

  // ? HOOK-STATE DE PAGINACION.
  const [ page, setPage ] = useState(0);
	const [ rowsPerPage, setRowsPerPage ] = useState(25);
  const [skipRow, setSkipRow] = useState(0);
  const [listadoDeEvaluacionesDinamico, setListadoDeEvaluacionesDinamico] = useState([]);
  const [validationActionNotify, setValidationActionNotify] = useState(0);
  const [loadingPagination, setLoadingPagination] = useState(true);

  // ? HOOK-STATE EMPLEADO
  const [empleado, setEmpleado] = useState('');
  const [evaluacionesLength, setEvaluacionesLength] = useState(0);

  // * FUNCION PARA ACTUALIZAR LA LISTA.
  const refreshList = () => dispatch(fetchListaEvaluacionesPorAno({ano}));

  // ? HOOK-EFFECT

  useEffect(() => {
    setEvaluacionesLength(0);
    refreshList()
    dispatch(fetchObtenerEstadoPeriodo(ano));
  }, [ano]);  


  useEffect(() => {
    if(isSuccess === 'success'){
      swal("Exitoso!", isMessage, "success");
      dispatch(fetchCambiarSuccess());
    }
    else if(isSuccess === 'error'){
      swal("Error", 'Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.', "error");
      dispatch(fetchCambiarSuccess());
    }
  }, [isSuccess])
  
  
  // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
  useEffect(() => {

    let inicio = rowsPerPage * page;
    let final = (rowsPerPage * page) + rowsPerPage;

    if(empleado.length > 0){
      
      // * FILTRADO DE NOMBRE EN EL ARRAY.
      const listFilter = listaEvaluacionesPorAno.filter((x) =>
      removeAccents(x.empleado).toLowerCase().includes(removeAccents(empleado.toLowerCase())) || removeAccents(x.evaluador).toLowerCase().includes(removeAccents(empleado.toLowerCase())));

      setListadoDeEvaluacionesDinamico(listFilter.slice(inicio, final));
      setEvaluacionesLength(listFilter.length);
    }
    else if(listaEvaluacionesPorAno.length > 0){
      setListadoDeEvaluacionesDinamico(listaEvaluacionesPorAno.slice(inicio, final));
      setEvaluacionesLength(listaEvaluacionesPorAno.length);

      setLoadingPagination(false);
    }

  }, [listaEvaluacionesPorAno, page, skipRow, rowsPerPage, empleado])  

  // * FUNCION PARA CAMBIAR EL ESTADO.
  const openModalCrear = () => {
    setTipoAccion(0);
    setOpen(true);
  }

  // * FUNCIONES PARA GENERAR NOTIFICACIONES
  const generarNotificacionesEvaluacionesAll = () => {
    swal({
      title: 'Confirmación',
      text: 'Estás seguro/a que desea notificar a todos los evaluadores?',
      icon: 'warning',
      buttons: ['No', 'Si']
  }).then(respuesta => {
      if(respuesta){
          dispatch(fetchEvaluacionNotificacionesAll(ano));
          refreshList();
      }
  })
  }

  // * FUNCIONES DE MODALS.
  const openModalEditar = (evaluado) => {
    setEvaluado(evaluado);
    setTipoAccion(1);
    setOpen(true);
  }

  const openModalNotificacion = (type) => {
    setValidationActionNotify(type);
    setOpenN(true);
  }

  // * FUNCION PARA NAVEGAR A OTRA PANTALLA.
  const openEvaluacionEmpleado = (hash) => {
    navigate(`/evaluaciones/desempeno/empleado/${hash}`);
  }

  // * FUNCION PARA LA PAGINACION
  const handleChangePage = (event, newPage) => {
		setSkipRow(newPage* rowsPerPage)
		setPage(newPage);
	};

  // * FUNCION PARA CALCULAR VALORES SEGUN EL GRUPO OCUPACIONAL.
  const calcGrupoOcupacionalLogroMetas = (value, grupoOcupacional) => {
    return grupoOcupacional === 5 ? (value * 100) / 65 : (value * 100) / 55
  }

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setSkipRow(0);
	};


  // * FUNCION QUE ELIMINA UNA EVALUACION
  const deleteEvaluacion = (id) => {
    swal({
        title: 'Eliminar',
        text: 'Estás seguro/a de eliminar esta evaluación?',
        icon: 'warning',
        buttons: ['No', 'Si']
    }).then(respuesta => {
        if(respuesta){
            dispatch(fetchEvaluacionEliminar(id));
            refreshList();
        }
    })
  }

  // * FUNCION PARA REABRIR UNA EVALUACION
  const openEvaluation = (evaluacionId) => {
    swal({
      title: 'Confirmación',
      text: 'Estás seguro/a que desea reabrir esta evaluación?',
      icon: 'warning',
      buttons: ['No', 'Si']
    }).then(respuesta => {
        if(respuesta){
            dispatch(fetchReabrirEvaluacion(evaluacionId));
            refreshList();
        }
    })
  }

  // * FUNCION PARA GENERAR REPORTE PDF.
  const generateReportEvaluacion = (item) => {
    if(item.ano < 2023){
      swal("Información", "Reporte en desarrollo, favor de usar esta función a partir del período 2023.", "warning");
    }
    else
    {
      dispatch(fetchGenerarEvaluacionEmpleadoPDF(item.evaluacionId));
    }
    
  }
  
  return (
    <Encabezado title={"Evaluaciones de desempeño"} ano={ano}>
        <BarraOpciones 
        openModalCrear={openModalCrear} 
        openModalNotificacion={openModalNotificacion}
        setEmpleado = {setEmpleado}
        empleado = {empleado}
        estadoPeriodo = {estadoPeriodo}
        route={'/evaluaciones/desempeno'}
        />        

        {
          listadoDeEvaluacionesDinamico.length > 0 
            && listadoDeEvaluacionesDinamico.map((item, index) => 

              <div className="card mb-2" key={index}>
                <div className="row g-0">
                  <div className="col-2 text-center mt-3 mb-3">
                    <p className="card-text fw-bold text-14">Evaluado</p>
                    <p className="text-14">{item.empleado}</p>
                  </div>

                  <div className="col-2">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Evaluador</p>
                      <p className="text-14">{item.evaluador}</p>
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Grupo ocupacional</p>
                      <p className="text-14">{item.grupoOcupacional}</p>
                    </div>
                  </div>

                  <div className="col-3">
                      <p>&nbsp;</p>
                      <div className="text-center text-14 mt-3 mb-3 d-flex justify-content-center">
                          <ProgressValueComponent value={calcGrupoOcupacionalLogroMetas(item.resultadoValor, item.grupoOcupacional)} ponderacion={item.resultadoValor}/>
                          
                            {
                              ano < 2023 && 
                                <>
                                  <Typography color='#003876'>+</Typography>
                                    <ProgressValueComponent value={Math.round(calcGrupoOcupacionalCompetencias(item.resultadoCompetenciaValor, item.grupoOcupacional))}/>
                                  <Typography color='#003876'>+</Typography>
                                    <ProgressValueComponent  value={item.resultadoRedValor * 100 / 15} ponderacion = {item.resultadoRedValor}/>
                                </>
                            }

                            <Typography color='#003876'>{ano < 2025 ? "=" : "+"}</Typography>
                            {
                              ano != 2025 ?
                                <ProgressValueComponent value={ano < 2023 ? item.totalEvaluacion : calcTotalEvaluaciones2023(item.totalEvaluacion, item.grupoOcupacional)}/>
                              :  
                                <>
                                  <ProgressValueComponent value={Math.round(calcGrupoOcupacionalCompetenciasComportamientos2024(item.resultadoCompetenciasComportamientosMap, item.grupoOcupacional))} ponderacion={item.resultadoCompetenciasComportamientosMap} />
                                  <Typography color='#003876'>=</Typography>
                                  <ProgressValueComponent value={Math.round(item.resultadoCompetenciasComportamientosMap + item.totalEvaluacion)} />
                                </>
                            }
                      </div>
                  </div>

                  <div className="col-1">
                    <div className="text-center mt-3 mb-3">
                      <p className="card-text fw-bold text-14">Estado</p>
                      {
                        (item.estado !== 0 && estadoPeriodo !== 1) && (permisosState.ROLE_ADMIN_EVALUACIONES | permisosState.ROLE_ADMIN) &&
                          <IconButton onClick={() => openEvaluation(item.evaluacionId)}>
                            <LockOpenTwoToneIcon sx={{color: '#003876', mt: -1.5}} />
                          </IconButton>
                      }
                      
                    </div>
                  </div>

                  <div className="col-2">
                    <div style={{marginTop: '2pc'}} className='d-flex justify-content-center'>
          
                      {
                        (item.estado === 0) && (permisosState.ROLE_ADMIN_EVALUACIONES | permisosState.ROLE_ADMIN) &&
                          <IconButton onClick={() => openModalEditar(item)}>
                            <ModeEditOutlineIcon sx={{color: '#003876' }} />
                          </IconButton>
                      }

                      {
                        (item.estado === 0) && (permisosState.ROLE_ADMIN_EVALUACIONES | permisosState.ROLE_ADMIN) &&
                          <IconButton onClick={() => deleteEvaluacion(item.evaluacionId)}>
                            <DeleteForeverIcon sx={{color: '#C72924' }} />
                          </IconButton>
                      }

                      {
                        (item.estado !== 0) && (permisosState.ROLE_ADMIN_EVALUACIONES | permisosState.ROLE_ADMIN) ?
                          <>

                            <div style={{marginTop: '22'}}>
                              <IconButton onClick={() => generateReportEvaluacion(item)}>
                                <TaskOutlinedIcon sx={{color: '#005215' }} />
                              </IconButton>

                                {
                                  ano >= 2024 && 
                                  <IconButton onClick={() => openEvaluacionEmpleado(item.hash)} id='btn-ver-detalle-evaluacion'>
                                    <FaEye color='#003876' />
                                  </IconButton>
                                }

                            </div>
                              
                          </>
                          :
                            <IconButton onClick={() => openEvaluacionEmpleado(item.hash)}>
                              <ExitToAppRoundedIcon sx={{color: '#003876' }} />
                            </IconButton>
                      }

                    </div>
                  </div>

                </div>
    
              </div>
          ) 
        }

        {
          listadoDeEvaluacionesDinamico.length > 0 &&
            <TablePagination
            component="div"
            count={evaluacionesLength}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }

        {/* MODALS */}
        <EvaluacionDetalleModal 
        open={open}
        setOpen={setOpen}
        tipoAccion={tipoAccion}
        evaluado={evaluado}
        setEvaluado={setEvaluado}
        setEmpleado={setEmpleado}
        periodo={parseInt(ano)}
        refreshList={refreshList}
        />

        <EvaluacionNotificacionModal 
        open={openN}
        setOpen={setOpenN}
        periodo={ano}
        isValidation={validationActionNotify}
        />

        <Loading open={isLoading || loadingPagination} />

    </Encabezado>
  );
};
