import React from 'react';
import { useSelector } from 'react-redux';

import { 
    Paper, 
    Stack, 
    Typography, 
    Radio, 
    FormControl, 
    FormLabel, 
    FormControlLabel, 
    RadioGroup, 
    Divider, 
    TableContainer, 
    TableHead, 
    Table,
    TableRow, 
    TableCell, 
    TableBody,
    Grid
} from '@mui/material';

import { 
    FaArrowCircleLeft,
    FaArrowCircleRight 
} from "react-icons/fa";
  
import { BiSave } from "react-icons/bi";
import swal from 'sweetalert';
import { removeAccents } from '../../../utils';

import { selectSemanaAlmuerzoActiva } from '../../../redux/Cafeteria/CafeteriaSlice';

export const SeleccionAlmuezoEmpleadoPage = ({
    categoriaAlmuerzoDiaEmpleado, 
    setStepPosition, 
    stepPosition, 
    diasSemanas, 
    seleccionMenuEmpleado,
    setSeleccionMenuEmpleado,
    resumenMenuState,
    sendDataToApi
}) => {

    // * ESTADO DE LA SEMANA ACTUAL.
    const { estatus } = useSelector(selectSemanaAlmuerzoActiva);

    // * DESESTRUCTURACION DE DATOS
    const { diaId, position } = stepPosition;

    // * DIA SELECCIONADO.
    const dia = diasSemanas.find(x => x.id === diaId)?.descripcion ?? '';

    // * FUNCION PARA PASAR DE PAGINAS.
    const nextPage = () => {

        const validate = Object.entries(seleccionMenuEmpleado[removeAccents(dia)])?.some(([key, value]) => 
            typeof value === 'object' ? value.length > 0 : value
        );
        
        if(!validate && categoriaAlmuerzoDiaEmpleado?.length > 0){
            swal("Información", "Debe seleccionar al menos una opción.", "warning");
            return;
        }

        // * PASAR AL SIGUIENTE DIA.
        setStepPosition({diaId: diaId + 1, position: position + 1 })
    }

  return (
    <Paper>
        <Stack p={3} minHeight='80%'>
            <Typography variant='h6'>{ position < 5 ? dia : "Resumen del Menú de la semana"}</Typography>
            <Divider />

                {
                    position === 5 &&
                    <TableContainer component={Paper} sx={{mt: 3}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 'bold'}}>
                                        Día
                                    </TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>
                                        Menú
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    diasSemanas.map(((x, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {x.descripcion}
                                            </TableCell>
                                            <TableCell>
                                                {resumenMenuState[removeAccents(x.descripcion)]}
                                            </TableCell>
                                        </TableRow>
                                    )))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                
                {
                    categoriaAlmuerzoDiaEmpleado?.length > 0 ?

                        <Grid container spacing={4}>
                        {
                            categoriaAlmuerzoDiaEmpleado?.map((item, index) => (
                          
                                <Grid item xs={4} key={index}>  

                                    <FormControl sx={{mt: 2}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">{item.descripcion}</FormLabel>
                                            <RadioGroup
                                            value={seleccionMenuEmpleado[removeAccents(dia)]?.[removeAccents(item.descripcion)] || ""}
                                            onChange={e =>  setSeleccionMenuEmpleado({
                                                ...seleccionMenuEmpleado,
                                                [removeAccents(dia)]: {
                                                    ...seleccionMenuEmpleado[removeAccents(dia)], 
                                                    [removeAccents(item.descripcion)]: e.target.value 
                                                }
                                            })}
                                            key={index}
                                            rownpm start
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            >
                                                {
                                                    item?.comidas?.map((item2, index2) => (
                                                        <FormControlLabel disabled={!estatus} key={index2} value={item2.comidaId} control={<Radio />} label={item2.descripcion} />
                                                    ))
                                                }
                                            
                                            </RadioGroup>
                                    </FormControl>


                                </Grid>

                            ))
                        }

                    </Grid>

                    : position < 5
                    ? <Typography mt={3} align='center'>No hay opciones disponibles</Typography>
                    : <></>
                       
                }
            
            <div className='mt-4 d-flex justify-content-end'>
                <button style={{marginRight: 4}} hidden={position === 0} onClick={() => setStepPosition({diaId: diaId - 1, position: position - 1 })} className='btn btn-primary-mem'><FaArrowCircleLeft style={{marginBottom: 4}} /> Anterior</button>
                <button hidden={position === 5} onClick={() =>  nextPage()} className='btn btn-primary-mem'>Siguiente <FaArrowCircleRight style={{marginBottom: 4}} /> </button>
                <button hidden={position < 5 || !estatus} onClick={() => sendDataToApi()} className='btn btn-primary-mem'>Guardar <BiSave style={{marginBottom: 3}} fontSize={22} /></button>
            </div>

        </Stack>
    </Paper>
  )
}
