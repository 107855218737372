import React, { useEffect } from 'react';
import { Encabezado } from '../../Components/Encabezado/Encabezado'
import { RegistrarMenusTabs } from '../../Components/Cafetería/Tabs/RegistrarMenuTabs';
import { useDispatch, useSelector } from 'react-redux';

import { 
  fetchGetSemanaAlmuerzoActiva,
  selectSemanaAlmuerzoActiva,
  selectValidacionSubsidioComidaEmpleadoAplica
} from '../../redux/Cafeteria/CafeteriaSlice';

export const DigitarEmpleadoMenuComida = () => {

  // ? HOOK DISPATCH
  const dispatch = useDispatch();
   
  // ? HOOK-SELECTOR
  const semana = useSelector(selectSemanaAlmuerzoActiva);
  const usuarioAplicaSubsidio = useSelector(selectValidacionSubsidioComidaEmpleadoAplica);

  // ? HOOK-EFFECT
  useEffect(() => {
    dispatch(fetchGetSemanaAlmuerzoActiva());
  }, [])
 
  return (
    <Encabezado title={`Menú de Almuerzo - ${semana.semanaLabel}`}>

      <RegistrarMenusTabs 
      usuarioAplicaSubsidio = {usuarioAplicaSubsidio}
      />

    </Encabezado>
  )
}
