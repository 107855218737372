import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance, {axiosApiInstanceFiles} from "../../utils/apiClient";
import { endpoints } from "../config";

const initialState = {
    isLoading: false,
    isSuccess: 'idle',
    message: "",
    listaNominasEmpleadosFiltrado: [],
    listaPeriodoAgregar: [],
    listaPeriodoVer: [],
    listaProgramas: [],
    periodoSubirNomina: [],
    listaEmpleadoNoEncontrados: [],
    listaNominasDetalle: [],
    listaNominaEmpleadoId: [],
    reporteAtributos: {},
    nominaEmpleado: {},
    estadoEnvioCorreos: false,
}

const mensajeErrorDefault = "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.";

export const fetchCambiarSuccess = createAsyncThunk("nomina/fetchCambiarSuccess",
    async () => {
      return null;
    }
);  

export const fetchNominaListaPeriodoAgregar = createAsyncThunk("nomina/fetchNominaListaPeriodoAgregar", 

    async () => {
        var resultado = await axiosApiInstance.get(endpoints.nominas.listaPeriodosNominasInsetar);
        return resultado.data;
    }
)

export const fetchNominaListaPeriodoVer = createAsyncThunk("nomina/fetchNominaListaPeriodoVer", 

    async () => {
        var resultado = await axiosApiInstance.get(endpoints.nominas.listaPeriodosNominasVer);
        return resultado.data;
    }
)

export const fetchNominaListaProgramas = createAsyncThunk("nomina/fetchNominaListaProgramas", 

    async () => {
        var resultado = await axiosApiInstance.get(endpoints.nominas.listaProgramas);
        return resultado.data;
    }
)

export const fetchFiltrarNominasEmpleados = createAsyncThunk("nomina/fetchFiltrarNominasEmpleados", 

    async (data) => {

        // * DESESTRUCTURACION DE LOS DATOS.
        const {ano, mes, programaId, areaOrganizacionalId, encargadoId} = data;

        var resultado = await axiosApiInstance.get(`${endpoints.nominas.filtrarNominasEmpleados}?Ano=${ano}&Mes=${mes}&ProgramaId=${programaId}&AreaOrganizacionalId=${areaOrganizacionalId}&EncargadoId=${encargadoId}`);
        return resultado.data;
    }
)

export const fetchVerNominaEmpleado = createAsyncThunk("nomina/fetchVerNominaEmpleado", 

    async (data) => {

        // * DESESTRUCTURACION DE LOS DATOS.
        const {empleadoId, ano, mes, programaId} = data;

        var resultado = await axiosApiInstance.get(`${endpoints.nominas.verNominaEmpleado}/${empleadoId}/${ano}/${mes}`);
        return resultado.data;
    }
)


// * REPORTE DE NOMINA DE EMPLEADO.

export const fetchReporteNominaEmpleado = createAsyncThunk(
    "/fetchReporteNominaEmpleado",
    async (data) => {

      const { empleadoId, ano, mes } = data; 

      const result = await axiosApiInstance({
        url: `${
          endpoints.nominas.imprimirReporteNominaEmpleado
        }/${empleadoId}/${ano}/${mes}`,
  
        method: "GET",
        responseType: "blob", 
      }).then((response) => {

        const href = URL.createObjectURL(response.data);

        const data = {
            link: href,
            nombre: `Reporte de Nómina (${empleadoId})`
        }

        return data;
      });

      return result;

    }
  );
  
// * LIMPIAR DATA DEL REPORTE DE NOMINA DE EMPLEADOS
export const fetchClearReporteNomina = createAsyncThunk("nomina/fetchClearReporteNomina",
    async () => {
    return null;
    }
);  

// * LIMPIAR DATA DEL LISTADO DE EMPLEADOS NO ENCONTRADOS
export const fetchClearEmpleadosNoEncontrados = createAsyncThunk("nomina/fetchClearEmpleadosNoEncontrados",
    async () => {
    return null;
    }
);  

export const fetchPeriodosSubirNomina = createAsyncThunk(
    "nomina/fetchPeriodosSubirNomina", 

    async () => {
        var resultado = await axiosApiInstance.get(endpoints.nominas.generarAñosSubirNomina);
        return resultado.data;
    }
)

// * SUBIR NOMINA
export const fetchSubirNomina = createAsyncThunk("nomina/fetchSubirNomina",
    async (nomina) => {

        // * DESESTRUCTURACION DE LOS DATOS.
        const {ano, mes, archivo} = nomina;

        let formData = new FormData();

        formData.append('file', archivo);

        var resultado = await axiosApiInstanceFiles.post(`${endpoints.nominas.subirNomina}?ano=${ano}&mes=${mes}`, formData);

        return resultado.data;
    }

)

// * GENERAR LISTA NOMINA DETALLE POR MES/AÑO.
export const fetchGenerarListaNominaDetalle = createAsyncThunk("nomina/fetchGenerarListaNominaDetalle", 

    async () => {

        var resultado = await axiosApiInstance.get(endpoints.nominas.listaNominasDetalle);
        return resultado.data;

    }
)

// * ELIMINAR UNA NOMINA.
export const fetchEliminarNomina = createAsyncThunk("nomina/fetchEliminarNomina", 

    async (data) => {

        const { carga } = data;

        var resultado = await axiosApiInstance.post(`${endpoints.nominas.eliminarNomina}?carga=${carga}`);
        return resultado.data;
    }
)

// * BUSCAR NOMINA DE UN EMPLEADO.
export const fetchNominaEmpleadoId = createAsyncThunk("nomina/fetchNominaEmpleadoId", 

    async (empleadoId) => {

        var resultado = await axiosApiInstance.get(`${endpoints.nominas.fetchVerMisNominas}?empleadoId=${empleadoId}`);
        return resultado.data;
    }
)

// * BUSCAR VERIFICAR ESTADO DE ENVIO DE CORREOS.
export const fetchVerificarEstadoEnvioCorreos = createAsyncThunk("nomina/fetchVerificarEstadoEnvioCorreos", 

    async () => {

        var resultado = await axiosApiInstance.get(endpoints.nominas.fetchVerificarEstadoEnvioCorreos);
        return resultado.data;
    }
)

// * NOTIFICAR ENVIO DE CORREO DE VOLANTE DE PAGOS.
export const fetchEnvioCorreos = createAsyncThunk("nomina/fetchEnvioCorreos", 

    async () => {

        var resultado = await axiosApiInstance.post(endpoints.nominas.fetchEnviarCorreos);
        return resultado.data;
    }
)


export const NominaSlice = createSlice({
    name:"nomina",
    initialState,
    reducers:{},
    extraReducers(builder){
        builder
        .addCase(fetchCambiarSuccess.fulfilled, (state) => {
            state.isSuccess = "idle";
        })
        .addCase(fetchNominaListaPeriodoAgregar.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchNominaListaPeriodoAgregar.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.listaPeriodoAgregar = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchNominaListaPeriodoAgregar.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.listaPeriodoAgregar = [];
            console.error(action.error.message);
        })
        .addCase(fetchNominaListaPeriodoVer.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchNominaListaPeriodoVer.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.listaPeriodoVer = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchNominaListaPeriodoVer.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.listaPeriodoVer = [];
            console.error(action.error.message);
        })
        .addCase(fetchNominaListaProgramas.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchNominaListaProgramas.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.listaProgramas = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchNominaListaProgramas.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.listaProgramas = [];
            console.error(action.error.message);
        })
        .addCase(fetchFiltrarNominasEmpleados.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchFiltrarNominasEmpleados.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.listaNominasEmpleadosFiltrado = action.payload.data;
                state.message = action.payload.message;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchFiltrarNominasEmpleados.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.listaNominasEmpleadosFiltrado = [];
            console.error(action.error.message);
        })
        .addCase(fetchVerNominaEmpleado.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchVerNominaEmpleado.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.nominaEmpleado = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchVerNominaEmpleado.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.nominaEmpleado = [];
            console.error(action.error.message);
        })
        .addCase(fetchReporteNominaEmpleado.pending, (state) => {
            state.isLoading = true;
            state.isSuccess = "idle";
            state.message = "";
          })
        .addCase(fetchReporteNominaEmpleado.fulfilled, (state, action) => {
           state.isLoading = false;
           state.reporteAtributos = action.payload;
        })
        .addCase(fetchReporteNominaEmpleado.rejected, (state, action) => {
            state.isSuccess = "error";
            state.message = mensajeErrorDefault;
            state.isLoading = false;
        })
        .addCase(fetchClearReporteNomina.fulfilled, (state) => {
            state.reporteAtributos = {};
        })
        .addCase(fetchPeriodosSubirNomina.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchPeriodosSubirNomina.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.periodoSubirNomina = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchPeriodosSubirNomina.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = mensajeErrorDefault;
            state.periodoSubirNomina = [];
            console.error(action.error.message);
        })
        .addCase(fetchSubirNomina.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchSubirNomina.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.listaEmpleadoNoEncontrados = action.payload.data?.empleadosNotFound ?? [];
                state.isSuccess = "success";
                state.message = action.payload.message;
            } 
            else {
                
                state.isSuccess = 'error';  
                state.message = action.payload.message;
            }
            state.isLoading = false;
        })
        .addCase(fetchSubirNomina.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = mensajeErrorDefault;
            state.listaEmpleadoNoEncontrados = [];
            console.error(action.error.message);
        })
        .addCase(fetchClearEmpleadosNoEncontrados.fulfilled, (state) => {
            state.listaEmpleadoNoEncontrados = [];
        })
        .addCase(fetchGenerarListaNominaDetalle.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchGenerarListaNominaDetalle.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.listaNominasDetalle = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchGenerarListaNominaDetalle.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.";
            state.listaNominasDetalle = [];
            console.error(action.error.message);
        })
        .addCase(fetchEliminarNomina.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchEliminarNomina.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.message = action.payload.message;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.payload.message;
                console.error(action.payload.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchEliminarNomina.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = mensajeErrorDefault;
            console.error(action.error.message);
        })
        .addCase(fetchNominaEmpleadoId.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchNominaEmpleadoId.fulfilled, (state, action) => {
            state.listaNominaEmpleadoId = action.payload.data;
            state.isLoading = false;
        })
        .addCase(fetchNominaEmpleadoId.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.listaNominaEmpleadoId = [];
            state.message = mensajeErrorDefault;
            console.error(action.error.message);
        })
        .addCase(fetchVerificarEstadoEnvioCorreos.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchVerificarEstadoEnvioCorreos.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.estadoEnvioCorreos = action.payload.data;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchVerificarEstadoEnvioCorreos.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            state.estadoEnvioCorreos = false;
            console.error(action.error.message);
        })
        .addCase(fetchEnvioCorreos.pending,(state, action) => {
            state.isLoading = true;
            state.isSuccess = 'idle';
        })
        .addCase(fetchEnvioCorreos.fulfilled, (state, action) => {

            if (action.payload.isSuccess) {
                state.isSuccess = 'success'; 
                state.message = action.payload.message;
            } 
            else {
                state.isSuccess = 'error'; 
                state.message = action.error.message;
                console.error(action.error.message);
            }
            state.isLoading = false;
        })
        .addCase(fetchEnvioCorreos.rejected,(state,action) => {
            state.isLoading = false;
            state.isSuccess = 'error'; 
            state.message = action.error.message
            console.error(action.error.message);
        })
        ;
    }
})

export const selectListaNominasEmpleadosFiltrado = (state) => state.nomina.listaNominasEmpleadosFiltrado;
export const selectListaPeriodoNominaAgregar = (state) => state.nomina.listaPeriodoAgregar;
export const selectListaPeriodoNominaVer = (state) => state.nomina.listaPeriodoVer;
export const selectListaProgramas = (state) => state.nomina.listaProgramas;
export const selectListaEmpleadoNomina = (state) => state.nomina.listaNominaEmpleadoId;
export const selectNominaEmpleado = (state) => state.nomina.nominaEmpleado;
export const selectNominaEmpleadoReporteAtributos = (state) => state.nomina.reporteAtributos;
export const selectPeriodoSubirNomina = (state) => state.nomina.periodoSubirNomina;
export const selectEmpleadoNoEncontradosNomina = (state) => state.nomina.listaEmpleadoNoEncontrados;
export const selectListaNominasDetalle = (state) => state.nomina.listaNominasDetalle;
export const selectEstadoEnvioCorreos = (state) => state.nomina.estadoEnvioCorreos;
export const selectIsLoading = (state) => state.nomina.isLoading
export const selectIsSuccess = (state) => state.nomina.isSuccess
export const selectMessage = (state) => state.nomina.message

export const {} = NominaSlice.actions;
export default NominaSlice.reducer;