import React, { useState, useEffect } from 'react'
import { Encabezado } from '../../Components/Encabezado/Encabezado'
import { NominaFiltroBusquedaColaboradoresCard } from '../../Components/Nomina/Card';
import { NominaEmpleadoCardLista } from '../../Components/Nomina/NominaEmpleadoCardLista';
import Loading from '../../Components/Modals/Loading/Loader';
import TablePagination from '@mui/material/TablePagination';
import swal from 'sweetalert';

import { 
    selectIsLoading,
    selectListaNominasEmpleadosFiltrado,
    selectIsSuccess,
    selectMessage,
    fetchCambiarSuccess
} from '../../redux/Nominas/NominaSlice';

import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';

export const VolantesPagosColaboradores = () => {


    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-STATE DE PAGINACION.
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(25);
    const [skipRow, setSkipRow] = useState(0);
    const [listaNominaEmpleadosFiltradoDinamico, setlistaNominaEmpleadosFiltradoDinamico] = useState([]);
    const [listaNominaEmpleadosFiltradoLength, setlistaNominaEmpleadosFiltradoLength] = useState(0);

    // ? HOOK-SELECTOR
    const isLoading = useSelector(selectIsLoading);
    const listaNominaEmpleadosFiltrado = useSelector(selectListaNominasEmpleadosFiltrado);
    const isSuccess = useSelector(selectIsSuccess);
    const message = useSelector(selectMessage);

    // * LIMPIAR SUCCESS
    const clearSuccess = () => dispatch(fetchCambiarSuccess());

    // ? HOOK-EFFECT PARA MOSTRAR LAS ALERTAS.
    useEffect(() => {

      if(isSuccess === 'success' && message.length > 0){
        swal("Exitoso!", message, "success");
        clearSuccess();
      } 
      else if(isSuccess === 'error'){
        swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
        clearSuccess();
      }

    }, [isSuccess])


    // ? FILTRADO DE LISTA A TRAVES DE USE-EFFECT.
    useEffect(() => {
  
      let inicio = rowsPerPage * page;
      let final = (rowsPerPage * page) + rowsPerPage;
  
      // * FILTRADO POR EMPLEADO.
      if(listaNominaEmpleadosFiltrado.length > 0){
        
        setlistaNominaEmpleadosFiltradoDinamico(listaNominaEmpleadosFiltrado.slice(inicio, final));
        setlistaNominaEmpleadosFiltradoLength(listaNominaEmpleadosFiltrado.length);
      }
      else if(listaNominaEmpleadosFiltrado.length === 0){
        setlistaNominaEmpleadosFiltradoDinamico([]);
        setlistaNominaEmpleadosFiltradoLength(0);
      }
  
    }, [listaNominaEmpleadosFiltrado, page, skipRow, rowsPerPage]);  
  

    // * FUNCION PARA LA PAGINACION
    const handleChangePage = (event, newPage) => {
      setSkipRow(newPage * rowsPerPage)
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
      setSkipRow(0);
    };

  return (
    <Encabezado title={'Volantes de Pagos Colaboradores'}>

        <NominaFiltroBusquedaColaboradoresCard />

        <NominaEmpleadoCardLista 
        listaNominaEmpleadosFiltradoLength={listaNominaEmpleadosFiltrado?.length ?? 0} 
        listaNominaEmpleadosFiltradoDinamico={listaNominaEmpleadosFiltradoDinamico}
        />

        {
            listaNominaEmpleadosFiltrado.length > 0 ?
                <TablePagination
                component="div"
                count={listaNominaEmpleadosFiltradoLength}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            : listaNominaEmpleadosFiltrado.length === 0 && isSuccess === 'success' &&
                <Typography variant='h6'>No se encontraron empleados.</Typography>
        }

      <Loading open={isLoading} />

    </Encabezado>
  )
}
