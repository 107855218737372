import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { TextField, Autocomplete } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Search, CloudDownload, Delete } from "@mui/icons-material";
import swal from "sweetalert";
import moment from "moment";

import {
  fetchAreasOrganizacionales,
  selectAllAreasOrganizacionales
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice";

import {
  fetchAllEmpleados,
  selectAllEmpleadosList
} from "./../../redux/Empleados/EmpleadosSlice";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";

import {
  onKeyPressEnterEmpleado,
  onKeyPressEnterOrganizacion,
} from "../../utils/funcionesKeyPress";
  
export const FiltroReporteAsistenciaCard = ({
  searchReporteAsistencia,
  isSuccess,
  setArea,
  ReporteAsistenciaExcel, 
  updateSuccess
}) => {
  const {
    formState: { errors },
    control
  } = useForm();

  // ? HOOK-DISPATCH
  const dispatch = useDispatch();

  // ? HOOK-SELECTOR
  const listAreaOrganizacionales = useSelector(selectAllAreasOrganizacionales);
  const listEmpleados = useSelector(selectAllEmpleadosList);

  // ? HOOK-STATE
  const [checked, setChecked] = useState(false);
  const [checkedStatusEmpleado, setCheckedStatusEmpleado] = useState(false);
  const [fechaDesde, setFechaDesde] = useState(new Date());
  const [fechaHasta, setFechaHasta] = useState(new Date());
  const [areaId, setAreaId] = useState(0);
  const [empleadoId, setEmpleadoId] = useState(0);
  const [estado, setEstado] = useState(1);
  const [todos, setTodos] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [listaAreasState, setListaAreasState] = useState([]);
  const [listaEmpleadosState, setListaEmpleadosState] = useState([]);

  // ? HOOK-EFFECT
  useEffect(() => {
    setEstado(checkedStatusEmpleado ? 0 : 1);
  }, [checkedStatusEmpleado]);


  // ? HOOK-EFFECT

  useEffect(() => {
   
    if(isSuccess === 'success'){
      swal("Exitoso!", "El reporte fue generado.", "success");
      updateSuccess();
    }
    else if(isSuccess === 'error'){
      swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
      updateSuccess();
    }

  }, [isSuccess])
  

  useEffect(() => {
    setTodos(checked);
  }, [checked]);  

  useEffect(() => {

    setListaAreasState([
      { id: 0, nombre: "Seleccionar Área" },
      ...listAreaOrganizacionales,
    ]);
    setListaEmpleadosState([
      { id: 0, nombreLabel: "Seleccionar Empleado" },
      ...listEmpleados,
    ]);
  }, [listAreaOrganizacionales, listEmpleados]);

  useEffect(() => {
    if (addRequestStatus === "success") {
      let filtro = {
        empleadoId,
        areaId,
        estado,
        desde: fechaDesde,
        hasta: fechaHasta,
        todos,
      };

      setArea(listAreaOrganizacionales[areaId - 1]?.nombre);
      searchReporteAsistencia(filtro);
      setAddRequestStatus("idle");
    }
  }, [addRequestStatus, isSuccess]);

  useEffect(() => {
    dispatch(fetchAllEmpleados());
    dispatch(fetchAreasOrganizacionales());
  }, []);

  // * FUNCION QUE LIMPIA LOS FILTROS.
  const clearFilter = (e) => {
    e.reset();
  };

  // * FUNCION QUE ENVIA LOS DATOS.
  const onSubmit = () => {
    setAddRequestStatus("success");
  };

  const onExport = () => {
    ReporteAsistenciaExcel({
      empleadoId,
      areaId,
      estado,
      desde: moment(fechaDesde).format('YYYY-MM-DD'),
      hasta: moment(fechaHasta).format('YYYY-MM-DD'),
      todos,
    })
  };

  return (
    <form onSubmit={clearFilter}>
      <div className="card mb-4">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label className="fw-bold">Fecha Desde</label>
                <Controller
                  control={control}
                  className="mb-3"
                  name="ReactDatepicker"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat='dd/MM/yyyy'
                        label={" "}
                        mask="__/__/____"
                        value={fechaDesde}
                        onChange={(e) => setFechaDesde(e)}
                        renderInput={(params) => (
                          <TextField
                            height={10}
                            className="form-control"
                            style={{ height: 10 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <p className="text-danger mt-3">
                  {errors.fechaInicio?.message}
                </p>
                <p className="text-danger">{errors.nombres?.message}</p>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="fw-bold">Fecha Hasta</label>
                <Controller
                  control={control}
                  className="mb-3"
                  name="ReactDatepicker"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        inputFormat='dd/MM/yyyy'
                        minDate={fechaDesde}
                        label={" "}
                        mask="__/__/____"
                        value={fechaHasta}
                        onChange={(e) => setFechaHasta(e)}
                        renderInput={(params) => (
                          <TextField
                            height={10}
                            className="form-control"
                            style={{ height: 10 }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
                <p className="text-danger mt-3">
                  {errors.fechaTermino?.message}
                </p>
                <p className="text-danger">{errors.nombres?.message}</p>
              </div>
            </div>
            <div className="form-group">
              <label className="fw-bold">Área</label>

              <Autocomplete
                id="select-areas"
                className="mb-2"
                disablePortal
                disableClearable
                getOptionLabel={(listaAreasState) =>
                  `${listaAreasState.nombre}`
                }
                options={listaAreasState}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                defaultValue={{ id: 0, nombre: "Seleccionar Área" }}
                noOptionsText={"No encontrado"}
                renderOption={(props, listaAreasState) => (
                  <Box
                    component="option"
                    {...props}
                    key={listaAreasState.id}
                    value={listaAreasState.id}
                  >
                    {listaAreasState.nombre}
                  </Box>
                )}
                renderInput={(params) => <TextField {...params} label={" "} />}
                onChange={(e) => setAreaId(parseInt(e.currentTarget.value))}
                onKeyUp={(e) =>
                  onKeyPressEnterOrganizacion(e, listaAreasState, setAreaId)
                }
              />

            </div>
            <div className="form-group">

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox-empleados"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  }
                  label="Todos los empleados pertenecientes a esta área "
                />
              </FormGroup>

              {/* <p className='text-danger mt-3'>{errors.fecha?.message}</p> */}
            </div>
            <div className="form-group">
              <label className="fw-bold">Empleado</label>

              <Autocomplete
                id="lista-empleados"
                className="mb-3"
                disablePortal
                disableClearable
                getOptionLabel={(listaEmpleadosState) =>
                  `${listaEmpleadosState.nombreLabel}`
                }
                defaultValue={
                  { nombreLabel: "Seleccionar Empleado", id: 0 } || null
                }
                options={listaEmpleadosState}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={"No encontrado"}
                renderOption={(props, listaEmpleadosState) => (
                  <Box
                    component="option"
                    {...props}
                    key={listaEmpleadosState.id}
                    value={listaEmpleadosState.id}
                  >
                    {listaEmpleadosState.nombreLabel}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    style={{ marginTop: 10 }}
                    {...params}
                    label={" "}
                  />
                )}
                onChange={(e) => setEmpleadoId(parseInt(e.currentTarget.value))}
                onKeyUp={(e) =>
                  onKeyPressEnterEmpleado(e, listaEmpleadosState, setEmpleadoId)
                }
              />

            </div>
            <div className="form-group">

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox-empleados-inactivos"
                      checked={checkedStatusEmpleado}
                      onChange={() =>
                        setCheckedStatusEmpleado(!checkedStatusEmpleado)
                      }
                    />
                  }
                  label="Incluir empleados inactivos pertenecientes a esta área "
                />
              </FormGroup>

              {/* <p className='text-danger mt-3'>{errors.fecha?.message}</p> */}
            </div>
            <div className="text-end">
              <button
                id="btn-buscar"
                type="button"
                className={
                  areaId > 0 || empleadoId > 0
                    ? "btn btn-primary-mem rounded me-2"
                    : "btn btn-primary-mem rounded me-2 disabled"
                }
                onClick={() => onSubmit()}
                title="Buscar"
              >
                <Search/>
              </button>
              <button
                id="btn-descargar"
                type="button"
                onClick={() => onExport()}
                className={ areaId > 0 || empleadoId > 0 ? 'btn btn-success rounded-6 me-2' : 'btn btn-success rounded-6 me-2 disabled'}
                title="Descargar"
              >
                <CloudDownload />
              </button>

                <a type="dow">

                </a>

              <button type="submit" id="btn-eliminar" className="btn btn-danger">
                <Delete />
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FiltroReporteAsistenciaCard;
