import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from "react-select";
import TextField from '@mui/material/TextField';
import  Avatar  from '@mui/material/Avatar';
import MaskedInput from "react-input-mask";
import CurrencyInput from "../../Components/InputSalario/CurrencyInput";
import Loading from '../../Components/Modals/Loading/Loader';
import ActualizarAjustarImagen  from '../../Components/Modals/Empleados/ActualizarAjustarImagen';
import { BiSave } from "react-icons/bi";
import { MdCancel } from "react-icons/md";

import {
	fetchEstadosCiviles,
	fetchProgramas,
	fetchUbicaciones,
	selectAllEstadosCiviles,
	selectAllPogramas,
	fetchTipoEmpleados,
	selectAllTiposEmpleados,
	selectUbicaciones
} from "../../redux/Utility/UtilitySlice";

import {
	fetchHorarios,
	selectAllHorarios,
} from "../../redux/Horarios/HorarioSlice"

import {
	fetchAreasOrganizacionales,
	selectAllAreasOrganizacionales,
} from "../../redux/AreaOrganizacional/AreaOrganizacionalSlice"

import {
	fetchCargosLista,
	selectAllCargosLista,
} from "../../redux/Cargos/CargosSlice"


import { 
	selectEmpleadosIsLoading,
	selectEmpleadosIsSuccess,
	selectEmpleadosMessage,
	updateEmpleado,
	fetchEmpleado,
	selectEmpleado, 
	fetchCambiarSuccess
} from '../../redux/Empleados/EmpleadosSlice';

import { convertNumberPhoneToString } from "../../utils/convertNumberPhoneToString";

import { formatCedula, formatPhoneNumber } from "../../utils/empleadoMaskInput";

export function EmpleadoEditar() {
    
	// ? HOOK-PARAMS
	const { empleadoId } = useParams();

	// ? HOOK-DISPATCH
	const dispatch = useDispatch();

	// ? HOOK-NAVIGATE
	const navigate = useNavigate();

	// ? HOOK-STATE
	const [addRequestStatus,setAddRequestStatus] = useState('idle')
	const [openActualizarImagen, setOpenActualizarImagen]= useState(false);

	// ? HOOK-SELECTOR
	const estadosCivil = useSelector(selectAllEstadosCiviles);
	const horarios = useSelector(selectAllHorarios);
	const programas = useSelector(selectAllPogramas);
	const tipoEmpleados = useSelector(selectAllTiposEmpleados);
	const areaOrganizacionales = useSelector(selectAllAreasOrganizacionales)
	const empleado = useSelector(selectEmpleado)
	const cargos = useSelector(selectAllCargosLista)
	const empleadosIsLoading = useSelector(selectEmpleadosIsLoading)
	const empleadosIsSuccess = useSelector(selectEmpleadosIsSuccess)
	const empleaodMessage = useSelector(selectEmpleadosMessage);
	const ubicaciones = useSelector(selectUbicaciones);

	// * FUNCION PARA REFRESCAR EMPLEADO
	const refreshEmpleado = () => dispatch(fetchEmpleado(empleadoId));
	
	// ? HOOK-EFFECT
	useEffect(()=>{
		dispatch(fetchEstadosCiviles());
		dispatch(fetchHorarios());
		dispatch(fetchProgramas());
		dispatch(fetchTipoEmpleados());
		dispatch(fetchAreasOrganizacionales());
		dispatch(fetchCargosLista());
		dispatch(fetchUbicaciones());
        refreshEmpleado();
	},[])

	useEffect(()=>{
		
		if(Object.keys(empleado).length > 0){
			setValue("empleado", {...empleado});
			
			setValue('empleado.celular', formatPhoneNumber(empleado.celular), {
				shouldValidate: true,
			});
			
			setValue('empleado.telefono', formatPhoneNumber(empleado.telefono), {
				shouldValidate: true,
			});
			
			setValue('empleado.flota', formatPhoneNumber(empleado.flota), {
				shouldValidate: true,
			});

			setValue('empleado.cedula', formatCedula(empleado.cedula), {
				shouldValidate: true,
			});
		}

    },[empleado])

	useEffect(()=>{
		
		if(empleadosIsSuccess === 'success' && addRequestStatus === 'success'){
			refreshEmpleado();
			dispatch(fetchCambiarSuccess());
			swal("Exitoso!", empleaodMessage, "success");
			setAddRequestStatus('idle');
		}
		else if(empleadosIsSuccess==='error' && addRequestStatus==='error'){
			swal("Error!", empleaodMessage, "error");
			setAddRequestStatus('idle');
		}

	},[empleadosIsSuccess])

	// ? HOOK-FORM
    const { 
		register, 
		formState: { errors }, 
		handleSubmit,
		setValue, 
		control,
		} = useForm({defaultValues:{empleado:{
        id:0,
        nombres:"",
        apellidos:"",
        fechaNacimiento: new Date(),
        cedula:"",
        emailInstitucional:"",
        emailPersonal:"",
        telefono:"",
        celular:"",
        flota:"",
        direccion:"",
        codigoEmpleado:"",
        sexo:0,
        estadoCivilId:0,
        tipoEmpleadoId:0,
        programaId:0,
        horarioId:0,
        fechaIngreso:new Date(),
        cargoId:0,
        areaOrganizacionalId:0, 
        areaReporteId:0,
        sueldo:0,
    }}});

	// * FUNCIONES
    const onSaveEmpleado=(empleadoUpdate)=>{

		try {

			setAddRequestStatus('success');

			const empleadoData = {...empleadoUpdate};
			empleadoData.empleado.cedula = empleadoUpdate.empleado.cedula?.replace(/_/g, "").split("-").join("");
			empleadoData.empleado.telefono = convertNumberPhoneToString(empleadoUpdate.empleado.telefono);
			empleadoData.empleado.celular = convertNumberPhoneToString(empleadoUpdate.empleado.celular);
			empleadoData.empleado.flota = convertNumberPhoneToString(empleadoUpdate.empleado.flota);
			empleadoData.empleado.sueldo = typeof(empleadoUpdate.empleado.sueldo) === 'number' ? empleadoUpdate.empleado.sueldo : empleadoUpdate.empleado.sueldo.replace('$', "").split(",").join("");
			
			dispatch(updateEmpleado(empleadoUpdate.empleado)).unwrap();
		} catch (error) {
			console.error(error);
			setAddRequestStatus('error');
		}
	}

	const imageUrl = useMemo(() => {
		return `${empleado?.foto}?cacheBust=${new Date().getTime()}`;
	  }, [empleado]);

	return (
		<div className="container">
			<div className="row mt-2">
				<form
					onSubmit={handleSubmit((data) => {
						onSaveEmpleado(data)
					})}
				>
					<div>
						<h3 className="text-primary-mem">EDITAR EMPLEADO</h3>
						<hr />
					</div>
					<div className="d-flex flex-row">
						<div className="col-md-6  pe-4  border-end">
                        <div hidden className="form-group">
								<input
									className="form-control"
									{...register('empleado.id')}
								/>
							</div>
							<div className='d-flex justify-content-center'>
   						            <Avatar
										sx={{
											width: 150,
											height: 150,
											marginLeft: 1,
											border: 'solid',
											borderWidth: 1,
											borderColor: '#003876',
											borderRadius:3
										}}
										alt={`${empleado?.nombres} ${empleado?.apellidos} `}
										src={imageUrl}
										variant="square"
										onClick={()=>setOpenActualizarImagen(true)}
     				  			     />
										
									
							</div>
							<div className="form-group">
								<label className="fw-bold">Nombres</label>
								<input
									className="form-control"
                                   
									{...register('empleado.nombres', { required: 'El nombre es requerido' })}
								/>
								<p className='text-danger'>{errors.empleado?.nombres?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Apelidos</label>
								<input
									className="form-control"
                              
									{...register('empleado.apellidos', { required: 'El apellido es requerido' })}
								/>
								<p className='text-danger'>{errors.empleado?.apellidos?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Cédula</label>
								<Controller
								name="empleado.cedula"
								control={control}
								defaultValue=""
								rules={{
								required: "La cédula es requerida",
								minLength: {
									value: 13,
									message: 'La cédula debe tener 11 dígitos'
								}
								}}
								render={({ field }) => (
								<MaskedInput
									mask="999-9999999-9"
									maskChar=""
									value={field.value}
									onChange={field.onChange}
									bef
								>
									{(inputProps) => (
									<input
										className="form-control"
										{...inputProps}
										type="text"
									/>
									)}
								</MaskedInput>
								)}
								/>
								<p className='text-danger'>{errors.empleado?.cedula?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Fecha Nacimiento</label>
                                <Controller
                                           control={control}
                                           name="ReactDatepicker"
                                         
										   {...register('empleado.fechaNacimiento', {
											required: 'La fecha de nacimiento es requerida'
										})}
                                           render={({ field }) => (
											<LocalizationProvider dateAdapter={AdapterDateFns} >
											<DatePicker
											inputFormat='dd/MM/yyyy'
											label={" "}
											mask="__/__/____"
											value={field.value}
											onChange={(e) => field.onChange(e)}
											renderInput={(params) => <TextField className='form-control' {...params} />}
											/>
										  </LocalizationProvider>
                                           )}
                                         />

								<p className='text-danger mt-3'>{errors.empleado?.fechaNacimiento?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Sexo</label>
								<select
									className="form-control"
                            
									{...register('empleado.sexo', { required: 'El sexo es requerido' })}
								>
									<option value={''}>--Seleccionar Sexo--</option>
								    <option  value={"Masculino"}>Masculino</option>
								    <option  value={"Femenino"}>Femenino</option>
																	
								</select>
								<p className='text-danger'>{errors.empleado?.sexo?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Estado Civil</label>
								<select
									className="form-control"
                                   
									{...register('empleado.estadoCivilId', { required: 'El estado civil es requerido' })}
								>
									<option value={''}>--Seleccionar Estado Civil--</option>
									{
										estadosCivil.map((estadoCivil)=><option key={estadoCivil.id} value={estadoCivil.id}>{estadoCivil.nombre}</option>)
									}
									
								</select>
								<p className='text-danger'>{errors.empleado?.estadoCivilId?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Correo Personal</label>
								<input
									className="form-control"
                                 
									{...register('empleado.emailPersonal', {
										pattern: {
											value: /\S+@\S+\.\S+/,
											message: 'El formato de correo no es valido'
										}
									})}
								/>
								<p className='text-danger'>{errors.empleado?.emailPersonal?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Dirección</label>
								<textarea className="form-control"   {...register('empleado.direccion')} />
								<p className='text-danger'></p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Celular</label>
									<Controller
									control={control}
									name="empleado.celular"
									rules={
									{
										minLength: {
										value: 14,
										message: 'El celular debe tener 10 dígitos'
									}}
									}
									render={({ field }) => (
									<MaskedInput
										mask="(999) 999-9999"
										min={10}
										maskChar=""
										value={field.value ?? ''}
										onChange={field.onChange}
									>
										{(inputProps) => (
										<input
											className="form-control"
											{...inputProps}
											type="text"
										/>
										)}
									</MaskedInput>
									)}
									/>
								<p className='text-danger'>{errors.empleado?.celular?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Telefono</label>
									<Controller
									control={control}
									name="empleado.telefono"
									rules={
									{
										minLength: {
										value: 14,
										message: 'El teléfono debe tener 10 dígitos'
									}}
									}
									render={({ field }) => (
									<MaskedInput
										mask="(999) 999-9999"
										maskChar={null}
										value={field.value ?? ''}
										onChange={field.onChange}
										beforeMaskedStateChange={field.value}
									>
										{(inputProps) => (
										<input
											className="form-control"
											{...inputProps}
											type="text"
										/>
										)}
									</MaskedInput>
									)}
									/>
								<p className='text-danger'>{errors.empleado?.telefono?.message}</p>
							</div>
						</div>
						<div className="col-md-6 ps-4 pe-4">
							<div className="form-group">
								<label className="fw-bold">Código Empleado</label>
								<input
									className="form-control"
									{...register('empleado.codigoEmpleado', { required: 'El codigo de empleado es requerido' })}
								/>
								<p className='text-danger'>{errors.empleado?.codigoEmpleado?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Fecha Ingreso</label>
								{/* <input
									className="form-control"
									{...register('fechaIngreso', { required: 'La fecha de ingreso es requerida' })}
								/> */}
								  <Controller
									control={control}
									name="ReactDatepicker"
									{...register('empleado.fechaIngreso', { required: 'La fecha de ingreso es requerida' })}
									render={({ field }) => (
									<LocalizationProvider dateAdapter={AdapterDateFns} >
									<DatePicker
									inputFormat='dd/MM/yyyy'
									label={" "}
									mask="__/__/____"
									value={field.value}
									onChange={(e) => field.onChange(e)}
									renderInput={(params) => <TextField className='form-control' {...params} />}
									/>
									</LocalizationProvider>
									)}
									/>
								<p className='text-danger mt-3'>{errors.empleado?.fechaIngreso?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Tipo Empleado</label>
								<select
									className="form-control"
									{...register('empleado.tipoEmpleadoId', { required: 'El tipo de empleado es requerido' })}
								>
									<option value={''}>Seleccionar Tipo Empleado</option>
									{
										tipoEmpleados.map((tipoEmpleado)=><option key={tipoEmpleado.id} value={tipoEmpleado.id}>{tipoEmpleado.tipo}</option>)
									}
								</select>
								<p className='text-danger'>{errors.empleado?.tipoEmpleadoId?.message}</p>
							</div>
                            <div className="form-group">
								<label className="fw-bold">Programa</label>
								<select
									className="form-control"
									{...register('empleado.programaId', { required: 'El programa es requerido' })}
								>
									<option value={''}>Seleccionar Programa</option>
									{
										programas.map((programa)=><option key={programa.id} value={programa.id}>{programa.programa}</option>)
									}
								</select>
								<p className='text-danger'>{errors.empleado?.programaId?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Horario</label>
								<select
									className="form-control"
									{...register('empleado.horarioId', { required: 'El horario es requerido' })}
								>
									<option value={''}>Seleccionar Horario</option>
									{
										horarios.map((horario)=><option key={horario.id} value={horario.id}>{horario.turnos[0]?.horarioLabel}</option>)
									}
								</select>
								<p className='text-danger'>{errors.empleado?.horarioId?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Sueldo</label>
								<Controller
								name="empleado.sueldo"
								control={control}
								defaultValue=""
								rules={{
								required: "El sueldo es requerido"
								}}
								render={({ field }) => (
									<CurrencyInput
										className="form-control"
										placeholder="$0.00" type="text"
										value={field.value}
										onChange={field.onChange}
									>
									
									</CurrencyInput>
								)}
								/>
							
								<p className="text-danger">
								{errors.empleado?.sueldo?.message}
								</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Correo Institucional</label>
								<input
									className="form-control"
									{...register('empleado.emailInstitucional', {
										pattern: {
											value: /\S+@\S+\.\S+/,
											message: 'El formato de correo no es valido'
										}
									})}
								/>
								<p className='text-danger'>{errors.empleado?.emailInstitucional?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Flota</label>
								<Controller
									control={control}
									name="empleado.flota"
									rules={
									{
										minLength: {
										value: 10,
										message: 'La flota debe tener 10 dígitos'
									}}
									}
									render={({ field }) => (
									<MaskedInput
										mask="(999) 999-9999"
										maskChar=""
										value={field.value ?? ''}
										onChange={field.onChange}
									>
										{(inputProps) => (
										<input
											className="form-control"
											{...inputProps}
											type="text"
										/>
										)}
									</MaskedInput>
									)}
									/>
								<p className='text-danger'>{errors.empleado?.flota?.message}</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Ubicación</label>
								<Controller
								control={control}
								name="empleado.ubicacionId"
								rules={
									{
									validate: value => value !== 0 || 'La ubicación es requerida',
									}
								}
								render={({ field }) => (
									<Select
										id="empleado.ubicacionId"
										classNamePrefix="select"
										isSearchable={true}
										placeholder="Seleccionar Ubicación"
										options={ubicaciones}
										getOptionLabel={(option) => option.descripcion}
										getOptionValue={(option) => option.id}
										onChange={(e) => field.onChange(e.id)}
										value={ubicaciones.find(x => x.id === field.value)}
									/>
								)}
								/>

								<p className="text-danger">
								{errors.empleado?.areaOrganizacionalId?.message}
								</p>
							</div>
							<div className="form-group">
								<label className="fw-bold">Área Organizacional</label>
								<Controller
								control={control}
								name="empleado.areaOrganizacionalId"
								rules={
									{
									validate: value => value !== 0 || 'El área organizacional es requerida',
									}
								}
								render={({ field }) => (
									<Select
										id="empleado.areaOrganizacionalId"
										classNamePrefix="select"
										isSearchable={true}
										placeholder="Seleccionar Área Organizacional"
										options={areaOrganizacionales}
										getOptionLabel={(option) => option.nombre}
										getOptionValue={(option) => option.id}
										onChange={(e) => field.onChange(e.id)}
										value={areaOrganizacionales.find(x => x.id === field.value)}
									/>
								)}
								/>
								<p className='text-danger'>{errors.empleado?.areaOrganizacionalId?.message}</p>
							</div>
                            <div className="form-group">
								<label className="fw-bold">Cargo</label>
								<Controller
								control={control}
								name="empleado.cargoId"
								rules={
									{
									validate: value => value !== 0 || 'El cargo es requerido'
									}
								}
								render={({ field }) => (
									<Select
									id="empleado.cargoId"
									classNamePrefix="select"
									isSearchable={true}
									placeholder="Seleccionar Cargo"
									options={cargos}
									getOptionLabel={(option) => option.titulo}
									getOptionValue={(option) => option.id}
									onChange={(e) => field.onChange(e.id)}
									value={cargos.find(x => x.id === field.value)}
									/>
								)}
								/>
								<p className='text-danger'>{errors.empleado?.cargoId?.message}</p>
							</div>
                            <div className="form-group">
								<label className="fw-bold">Área de Reporte</label>
								<Controller
								control={control}
								name="empleado.areaReporteId"
								rules={
								{
									validate: value => value !== 0 || 'El área reporte es requerido',
								}
								}
								render={({ field }) => (
									<Select
										id="empleado.areaReporteId"
										classNamePrefix="select"
										isSearchable={true}
										placeholder="Seleccionar Área Reporte"
										options={areaOrganizacionales}
										getOptionLabel={(option) => option.nombre}
										getOptionValue={(option) => option.id}
										onChange={(e) => field.onChange(e.id)}
										value={areaOrganizacionales.find(x => x.id === field.value)}
									/>
								)}
								/>
								<p className='text-danger'>{errors.empleado?.areaReporteId?.message}</p>
							</div>
						</div>
					</div>
					
					<div className="text-center mt-3 mb-3">
						<button type="submit"  id='btn-guardar' className="btn btn-primary-mem me-2">
							Guardar <BiSave fontSize={22} />
						</button>

						<button id='btn-cancelar' className="btn btn-danger" onClick={()=>navigate(empleado?.estado ? "/empleados" : "/empleados/empleadosdesvinculados")}>
							Cancelar <MdCancel fontSize={22} />
						</button>
					</div>
				</form>
				<Loading 
				open={empleadosIsLoading} 
				/>

				<ActualizarAjustarImagen 
				openActualizarImagen={openActualizarImagen} 
				setOpenActualizarImagen={setOpenActualizarImagen} 
				empleadoId ={empleado.id} 
				setAddRequestStatus={setAddRequestStatus} 
				/>

			</div>
		</div>
	);
}

export default EmpleadoEditar;
