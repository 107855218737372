import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Encabezado } from '../../Components/Encabezado/Encabezado';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatNumberRD } from '../../utils/formatNumberRD';
import Loading from "../../Components/Modals/Loading/Loader";
import numbro from 'numbro';
import swal from 'sweetalert';

import { 
    fetchVerNominaEmpleado, 
    fetchCambiarSuccess,
    selectNominaEmpleado,
    selectIsLoading,
    selectIsSuccess
} from '../../redux/Nominas/NominaSlice';

export const NominaEmpleado = () => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK SELECTOR
    const nominaEmpleado = useSelector(selectNominaEmpleado);
    const isLoading = useSelector(selectIsLoading);
    const isSuccess = useSelector(selectIsSuccess);

    // ? HOOK DE NAVEGACION
    const navigate = useNavigate();

    // * DESESTRUCTURACION DEL ESTADO DEL HOOK.
    const { state } = useLocation();

    // * ESTILOS
    const paperStyle = {
        padding: 1.5, 
        textAlign: 'center'
    };

    const style = {
        width: '100%',
        maxWidth: '100%',
        bgcolor: 'background.paper',
    };

    // * LIMPIAR SUCCESS
    const clearSuccess = () => dispatch(fetchCambiarSuccess());

     // ? HOOK-EFFECT PARA MOSTRAR LAS ALERTAS.
    useEffect(() => {
    
        if(isSuccess === 'error'){
            swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
            clearSuccess();
        }

    }, [isSuccess])

    useEffect(() => {
      
        if(state === null){
            navigate('/nominas/vernominas');
        }
        else{

            const fecha = state?.fechaNomina?.split('/')

            const data = {
                empleadoId: state?.empleadoId,
                ano: fecha[1],
                mes: fecha[0]
            };

            // * REALIZANDO PETICION A LA API.
            dispatch(fetchVerNominaEmpleado(data));
        }

    }, [state]);

  return (
    <Encabezado title='DETALLE DE NÓMINA'>

        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={2}>

                <Grid item xs={5}> 

                    <Paper elevation={4} sx={paperStyle} >

                        <Box
                        sx={{ fontSize: '16px', textTransform: 'uppercase', textAlign: 'initial' }}
                        >
                        Datos del Empleado
                        </Box>

                        <Divider />

                            <Grid item marginTop={1}>
                               
                               <Paper elevation={2}>
                                    <List sx={style} component="nav" aria-label="mailbox folders">

                                        <ListItem divider>
                                            <Typography fontSize={15}><b>Número de documento:</b> {nominaEmpleado?.empleadoNominaInfo?.cedula}</Typography>
                                        </ListItem>
                     
                                        <ListItem divider>
                                            <Typography fontSize={15}><b>Empleado:</b> {nominaEmpleado?.empleadoNominaInfo?.empleado}</Typography>
                                        </ListItem>

                                        <ListItem divider>
                                            <Typography fontSize={15}><b>Cargo:</b> {nominaEmpleado?.empleadoNominaInfo?.cargo}</Typography>
                                        </ListItem>
                                       
                                        <ListItem divider>
                                            <Typography fontSize={15}><b>Departamento:</b> {nominaEmpleado?.empleadoNominaInfo?.departamento}</Typography>
                                        </ListItem>

                                        <ListItem divider>
                                            <Typography fontSize={15}><b>Tipo Empleado:</b> {nominaEmpleado?.empleadoNominaInfo?.tipoEmpleado}</Typography>
                                        </ListItem>

                                        <ListItem >
                                            <Typography fontSize={15}><b>Período de nómina:</b> {nominaEmpleado?.empleadoNominaInfo?.periodoNomina}</Typography>
                                        </ListItem>
                                    
                                    </List>
                               </Paper>

                            </Grid>
                       
                    </Paper>

                </Grid>

                <Grid item xs={7}>

                    <TableContainer component={Paper}>
                        <Table  aria-label="simple table">
                                
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{fontWeight: 'bold'}}>Concepto</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>Ingresos Brutos</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>Descuentos</TableCell>
                                    <TableCell sx={{fontWeight: 'bold'}}>Ingresos netos</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>

                                {
                                    nominaEmpleado?.nominas?.map((item, index) => (
                                        <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {item.concepto}
                                            </TableCell>
                                            
                                            <TableCell align="center" sx={{color: 'green'}}>
                                                {
                                                    item.tipo === 'A' && numbro(item.valor).formatCurrency(formatNumberRD)
                                                }
                                            </TableCell>
                                            
                                            <TableCell align="right" sx={{color: 'red'}}>
                                                {
                                                    item.tipo === 'D' && `- ${numbro(item.valor).formatCurrency(formatNumberRD)}`
                                                }
                                            </TableCell>
        
                                            <TableCell align="right">
        
                                            </TableCell>
        
                                        </TableRow>
                                    )).reverse()
                                }

                                        <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell>

                                            </TableCell>
                                                
                                            <TableCell align="right" sx={{color: 'green', fontWeight: 'bold'}}>
                                                {numbro(nominaEmpleado?.ingresos).formatCurrency(formatNumberRD)}
                                            </TableCell>
                                            
                                            <TableCell align="right" sx={{color: 'red', fontWeight: 'bold'}}>
                                                {numbro(nominaEmpleado?.descuentos).formatCurrency(formatNumberRD)}
                                            </TableCell>

                                            <TableCell align="right"  sx={{color: '#0087FF', fontWeight: 'bold'}}>
                                                {numbro(nominaEmpleado?.neto).formatCurrency(formatNumberRD)}
                                            </TableCell>

                                        </TableRow>
                                
                                
                            </TableBody>

                        </Table>
                    </TableContainer>

                </Grid>

            </Grid>
        </Box>

        <Loading open={isLoading} />

    </Encabezado>
  )
}
