import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosApiInstance from "../../utils/apiClient";
import { apiBase } from "../config";
import { endpoints } from "../config";
const initialState = {
  isLoading: false,
  isSuccess: false,
  message: "",
  cargos: [],
  cargosLista: [],
  cargo: {},
  cargosCount: 0,
  cargosAll:[],
};

// * MENSAJE A MOSTRAR DE UN ERROR 500 EN API.
let messageFrontEnd = "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.";

// * LIMPIAR SUCCESS
export const fetchCambiarSuccess = createAsyncThunk(
	"permisos/fetchCambiarSuccess",
	async () => {
	  return null;
	}
);

export const fetchCargosAll = createAsyncThunk('cargos/fetchCargosAll', async () => {

  const cargos = await axiosApiInstance.get(`${endpoints.cargos.fetchLista}`);
  return cargos.data;
});

export const fetchCargos = createAsyncThunk(
  "cargos/fetchCargos",
  async (filter) => {
    const { skipRow, rowsPerPage, grupoOcupacionalId, cargo } = filter;
    try {
      var resultado = await axiosApiInstance.get(
        `${apiBase}Cargos?index=${Number(skipRow)}&itemsPerPage=${Number(
          rowsPerPage
        )}&cargo=${cargo}&grupoOcupacionalId=${grupoOcupacionalId}`
      );
      return resultado.data;
    } catch (error) {
      return error;
    }
  }
);

export const fetchCargosLista = createAsyncThunk(
  "cargos/fetchCargosLista",
  async () => {
      var resultado = await axiosApiInstance.get(`${apiBase}Cargos/lista`);
      return resultado.data;
  }
);
export const fetchCargo = createAsyncThunk("cargos/fetchCargo", async () => {
  try {
    var resultado = await axiosApiInstance.get(`${apiBase}Cargo/1`);
    return resultado.data;
  } catch (error) {
    return error;
  }
});

export const addCargo = createAsyncThunk(
  "cargos/addCargo",
  async (newCargo) => {
    try {
      var resultado = await axiosApiInstance.post(
        `${apiBase}cargo/nuevo`,
        newCargo
      );
      return resultado.data;
    } catch (error) {
      return error;
    }
  }
);

export const updateCargo = createAsyncThunk(
  "cargos/updateCargo",
  async (cargo) => {
    try {
      var resultado = await axiosApiInstance.post(
        `${apiBase}cargo/modificar`,
        cargo
      );
      return resultado.data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteCargo = createAsyncThunk(
  "cargos/deleteCargo",
  async (cargo) => {
    try {
      var resultado = await axiosApiInstance.post(
        `${apiBase}cargo/eliminar`,
        cargo
      );
      return resultado.data;
    } catch (error) {
      return error;
    }
  }
);

export const fetchCargosCount = createAsyncThunk(
  "cargos/fetchCargosCount",
  async (filter) => {
    const { grupoOcupacionalId, cargo } = filter;
    try {
      var resultado = await axiosApiInstance.get(
        `${apiBase}cargos/total?grupoOcupacionalId=${Number(
          grupoOcupacionalId
        )}&cargo=${cargo}`
      );
      return resultado.data;
    } catch (error) {
      return error;
    }
  }
);

export const CargosSlice = createSlice({
  name: "areaOrganizacional",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCambiarSuccess.fulfilled, (state) => {
        state.isSuccess = "idle";
      })
      .addCase(fetchCargos.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCargos.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.isSuccess;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.cargos = action.payload.data;
        }
      })
      .addCase(fetchCargos.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = messageFrontEnd;
        console.error(action.error.message);
        state.cargos = [];
      })
      .addCase(fetchCargosLista.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCargosLista.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.isSuccess;
        state.message = action.payload.message;
   
        if (action.payload.isSuccess) {
          state.cargosLista = action.payload.data;
        }
      })
      .addCase(fetchCargosLista.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = messageFrontEnd;
        console.error(action.error.message);
        state.cargosLista = [];
      })
      .addCase(fetchCargo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchCargo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.isSuccess;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.cargo = action.payload.data;
        }
      })
      .addCase(fetchCargo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = messageFrontEnd;
        console.error(action.error.message);
        state.cargo = {};
      })
      .addCase(addCargo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(addCargo.fulfilled, (state, action) => {

        if (action.payload.isSuccess) {
          state.isSuccess = 'success';
        }
        else
        {
          state.isSuccess = 'error';
        }

        state.isLoading = false;
        state.message = action.payload.message;

      })
      .addCase(addCargo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = messageFrontEnd;
        console.error(action.error.message);
        state.cargo = {};
      })
      .addCase(updateCargo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateCargo.fulfilled, (state, action) => {

        if (action.payload.isSuccess) {
          state.isSuccess = 'success';
        }
        else
        {
          state.isSuccess = 'error';
        }

        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(updateCargo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message;
        state.cargo = {};
      })
      .addCase(deleteCargo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteCargo.fulfilled, (state, action) => {

        if (action.payload.isSuccess) {
          state.isSuccess = 'success';
        }
        else
        {
          state.isSuccess = 'error';
        }

        state.isLoading = false;
        state.message = action.payload.message;
      })
      .addCase(deleteCargo.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.message = messageFrontEnd;
        console.error(action.error.message);
        state.cargo = {};
      })
      .addCase(fetchCargosCount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = action.payload.isSuccess;
        state.message = action.payload.message;
        if (action.payload.isSuccess) {
          state.cargosCount = action.payload.data;
        }
      })
      .addCase(fetchCargosAll.pending, (state) => {
				state.isLoading = true;
				state.isSuccess = 'idle';
			})
			.addCase(fetchCargosAll.fulfilled, (state, action) => {
				state.isLoading = false;
				state.message = action.payload.message;

				if (action.payload.isSuccess) {
					state.cargosAll = action.payload.data;
					state.isSuccess = 'success';
				} else {
					state.isSuccess = 'error';
					state.cargosAll = [];
				}
			})
			.addCase(fetchCargosAll.rejected, (state, action) => {
				state.isLoading = false;
				state.message = messageFrontEnd;
        console.error(action.error.message);
				state.isSuccess = 'error';
			});
      ;
  },
});

export const selectAllCargos = (state) => state.cargos.cargosAll;
export const selectAllCargosLista = (state) => state.cargos.cargosLista;
export const selectCargo = (state) => state.cargos.cargo;
export const selectCargoListFilter = (state) => state.cargos.cargos;
export const selectMessage = (state) => state.cargos.message;
export const selectIsSuccess = (state) => state.cargos.isSuccess;
export const selectIsLoading = (state) => state.cargos.isLoading;
export const SelectCargosCount = (state) => state.cargos.cargosCount;

// export const {} = CargosSlice.actions;
export default CargosSlice.reducer;
