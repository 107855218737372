import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
    Stack, 
    Paper, 
    Divider, 
    Typography, 
    Table,
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
    IconButton
} from '@mui/material';

import { 
    FaArrowCircleLeft,
    FaArrowCircleRight 
} from "react-icons/fa";

import { BiSave } from "react-icons/bi";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'

import { 
    Controller
} from 'react-hook-form';

import swal from 'sweetalert';

import { removeAccents } from '../../../utils';
import { 
    selectCategoriasAlmuerzo, 
    fetchSaveMenuComidaRRHH 
} from '../../../redux/Cafeteria/CafeteriaSlice';

export const RegistroMenuComidaRRHHPage = (
    {
        position, 
        diaId, 
        diasSemanas, 
        comidasState, 
        setSeleccionMenuComidaSemanalState, 
        seleccionMenuComidaSemanalState, 
        categoriasComidaState,
        setAddNewItemComida,
        form,
        setStepPosition
    }) => {     

    // * DIA SELECCIONADO.
    const { descripcion : dia = '', id} = diasSemanas.find(x => x.id === diaId) ?? {};
    const diaNormalized = removeAccents(dia).toLowerCase();
    const categoriasAlmuerzo = useSelector(selectCategoriasAlmuerzo);   

    // ? HOOK-FORM
    const { setValue, watch, control, register } = form;

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // * FUNCION PARA REGISTRAR UNA NUEVA COMIDA.
    const handleChange = (value, actionMeta) => {
        // console.group('Value Changed');
        // console.log(value);
        // console.groupEnd();

        if(watch('categoriaId') === 0){
            swal("Error!", "Debe seleccionar una categoría", "error");
            return;
        }

        const addNew = {id: Math.random(), descripcion: value, categoriaAlmuerzoId: watch('categoriaId'), nuevo: true};
        setAddNewItemComida(addNew)
    }

    // * FUNCION PARA GUARDAR LA SELECCION DE COMIDA POR DIA.
    const saveSeleccionMenuComidaPorDia = () => {

        const comida = comidasState
        .map(x => x.options || [])
        .flat()
        .find(y => y.id === watch('comidaId')) ?? {}

        // * VALIDACION QUE VERIRIFICA SI LA COMIDA YA FUE AGREGADA AL DIA ACTUAL, EVITANDO DUPLICIDAD.
        const validateComidaExist = seleccionMenuComidaSemanalState[diaNormalized]?.some(x => x.id === watch('comidaId')) ?? false;

        if(validateComidaExist){
            swal("Error", "La comida ya fue agregada.", "error");
            return;
        }

        // * GUARDANDO LA SELECCION.
        setSeleccionMenuComidaSemanalState((prevState) => ({
            ...prevState,
            [diaNormalized]: [
              ...(prevState[diaNormalized] || []),
              {...comida, diaId: id},
            ],
        }));
    }    
    
    // * FUNCION PARA ELIMINAR UAN COMIDA POR SU ID DEL DIA PRESENTE.
    const deleteComidaSeleccionada = (id) => {

        // * FILTRAR LISTA POR EL ID QUE SE QUIERE QUE NO SE TOME EN CUENTA. 
        const updatedListSeleccionMenuComidaSemanalState = seleccionMenuComidaSemanalState[diaNormalized]?.filter(x => x.id !== id) ?? [];

        // * GUARDANDO LA SELECCION.
        setSeleccionMenuComidaSemanalState((prevState) => ({
            ...prevState,
            [diaNormalized]: updatedListSeleccionMenuComidaSemanalState
        }));
    }

    // * FUNCION PARA PASAR DE PAGINAS.
    const nextPage = () => {

        // * VALIDACION QUE VERIFICA SI EXISTE ALGUNA COMIDA AGREGADA AL DIA ACTUAL, EVITANDO QUE PASE AL SIGUIENTE DIA
        // * SIN ELEGIR NINGUNA COMIDA.
        const validate = seleccionMenuComidaSemanalState[removeAccents(diaNormalized)]?.length > 0 ? true : false;        

        if(!validate){
            swal("Información", "Debe seleccionar al menos una opción.", "warning");
            return;
        }

        // * PASAR AL SIGUIENTE DIA.
        setStepPosition({diaId: diaId + 1, position: position + 1 })
    }

    const saveData = () => {

        const arrComidas = Object.keys(seleccionMenuComidaSemanalState).map(item => seleccionMenuComidaSemanalState[item])?.flat() ?? [];

        if(arrComidas.length === 0){
            return;
        }

        let arrComidaSeleccionNew = [];

        for (let i = 0; i < arrComidas.length; i++) {
            const comida = arrComidas[i];

            let comidaAddNew = {
                tipoAlmuerzoId: 1,
                diaId: comida.diaId,
                semanaId: 3,
                comidaId: comida.id
            }

            if(comida?.nuevo){
                comidaAddNew = { ...comidaAddNew, 
                    comidaId: 0, 
                    comida: {
                        id: 0,
                        descripcion: comida.descripcion,
                        categoriaAlmuerzoId: comida.categoriaAlmuerzoId,
                        estado: true
                    }
                }
            }

            arrComidaSeleccionNew.push(comidaAddNew);
        }

        dispatch(fetchSaveMenuComidaRRHH(arrComidaSeleccionNew));
    }

    // console.log(seleccionMenuComidaSemanalState);
    
        

    // * COMPONENTE PERSONALIZADO PARA CREAR LAS TABLAS DONDE SE MOSTRARAN LAS COMIDAS POR SU CATEGORIA.
    const TableCategoriaComida = ({categoriaId}) => (
        <Table component={Paper} sx={{mt: 4, width: '30%'}}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography fontWeight='bold'>
                            {categoriasComidaState.find(x => x.id === categoriaId)?.descripcion}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {
                    seleccionMenuComidaSemanalState[diaNormalized]?.filter(x => x.categoriaAlmuerzoId === categoriaId).map((item, index) => 
                        <TableRow key={index}>
                        
                            <TableCell >
                                {item.descripcion}
                            </TableCell>

                            <TableCell>
                                <IconButton>
                                    <MdDeleteForever color='red' onClick={() => deleteComidaSeleccionada(item.id)} />
                                </IconButton>
                            </TableCell>

                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    )



  return (
    <Paper>
        <Stack p={3} minHeight='80%'>
            <Typography variant='h6'>{ position < 5 ? dia : "Resumen del Menú de la semana"}</Typography>
            <Divider />

            {
                position < 5 ? (

                <>
                    
                    <Stack mt={2}>
                        <Typography fontWeight='bold'>Comida</Typography>

                        <Controller
                        control={control}
                        name="comidaId"
                        {...register("comidaId")}
                        render={({ field }) => (
                            <CreatableSelect 
                            onCreateOption={handleChange}
                            isClearable 
                            options={comidasState} 
                            getOptionLabel={(option) => option.descripcion}
                            getOptionValue={(option) => option.id}
                            formatCreateLabel={(inputValue) => `Crear "${inputValue}"`}
                            onChange={(e) => field.onChange(e.id ?? 0)}
                            value={comidasState
                                .map(x => x.options || []) // Asegúrate de que sea un array
                                .flat()
                                .find(y => y.id === field.value) ?? {}}
                            />
                        )}
                        />

                        </Stack>

                        <Stack mt={2}>
                        <Typography fontWeight='bold'>Categoría</Typography>

                        <Controller
                        control={control}
                        name="categoriaId"
                        {...register("categoriaId")}
                        render={({ field }) => (
                            <Select
                            id='select-categoria-almuerzo'
                            classNamePrefix="select"
                            isSearchable={true}
                            placeholder="Seleccionar Categoría"
                            options={categoriasComidaState}
                            getOptionLabel={(option) => option.descripcion}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => field.onChange(e.id ?? 0)}
                            value={categoriasComidaState?.find(x => x.id === field.value)}
                            />
                        )}
                        />

                        <div className='d-flex justify-content-end mt-3'>
                            <button onClick={() => saveSeleccionMenuComidaPorDia()} className='btn btn-primary'>Agregar <IoMdAddCircleOutline fontSize={20} /></button>
                        </div>
                    
                    </Stack>

                    <div className='d-flex justify-content-between'>
                        
                        <TableCategoriaComida
                        categoriaId={1}
                        />

                        <TableCategoriaComida
                        categoriaId={2}
                        />
                   
                        <TableCategoriaComida
                        categoriaId={3}
                        />
                        
                    </div>

                </>

            )

            : 
            
            <>
                <Table component={Paper} sx={{mt: 2}}>
                    {
                        Object.keys(seleccionMenuComidaSemanalState).map((dia, index) => (
                            <React.Fragment key={index}>
                                {/* Encabezado del día */}
                                <TableRow>
                                    <TableCell colSpan={categoriasAlmuerzo.length}>
                                        <Typography fontSize={16} fontWeight='bold'>{diasSemanas.find(x => removeAccents(x.descripcion).toLowerCase() === dia)?.descripcion}</Typography>
                                    </TableCell>
                                </TableRow>
                                
                                {/* Filas de categorías y comidas */}
                                <TableRow>
                                    {
                                        categoriasAlmuerzo.map((categoria, index2) => (
                                            <TableCell key={index2}>
                                                {/* Nombre de la categoría */}
                                                <strong>{categoria.descripcion}</strong>
                                                
                                                {/* Comidas de la categoría */}
                                                <Table>
                                                    <TableRow>
                                                        <TableCell key={index2}>
                                                            <ul>
                                                                {
                                                                    seleccionMenuComidaSemanalState[dia]
                                                                    .filter(x => x.categoriaAlmuerzoId === categoria.id)
                                                                    .map(({descripcion}, index3) => (
                                                                        <li>
                                                                            {descripcion}
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </React.Fragment>
                        ))
                    }
                </Table>
            
            </>
            }

            <div className='mt-4 d-flex justify-content-end'>
                <button style={{marginRight: 4}} hidden={position === 0} onClick={() => setStepPosition({diaId: diaId - 1, position: position - 1 })} className='btn btn-primary-mem'><FaArrowCircleLeft style={{marginBottom: 4}} /> Anterior</button>
                <button hidden={position === 5} onClick={() =>  nextPage()} className='btn btn-primary-mem'>Siguiente <FaArrowCircleRight style={{marginBottom: 4}} /> </button>
                <button hidden={position < 5} onClick={() => saveData()} className='btn btn-primary-mem'>Guardar <BiSave style={{marginBottom: 3}} fontSize={22} /></button>
            </div>
           
        </Stack>
    </Paper>
  )
}
