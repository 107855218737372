import React, { useState, useEffect } from 'react'
import { Encabezado } from '../../../Components/Encabezado/Encabezado'
import { IconButtonText } from '../../../Components/IconPersonalize/IconButtonText'
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AgregarBonoEscolarModal } from '../../../Components/Beneficios/Modal/AgregarBonoEscolarModal';
import moment from 'moment';
import swal from 'sweetalert';
import Loading from "../../../Components/Modals/Loading/Loader";
import { 
    fetchBonoEscolarPeriodos, 
    fetchBonoEscolarPorPeriodoActualizarBeneficiarios,
    fetchBonoEscolarCerrarPeriodo,
    fetchCambiarSuccess, 
    selectBonoEscolarPeriodos, 
    selectIsSuccess, 
    selectMessage, 
    selectIsLoading 
} from '../../../redux/Beneficios/BonoEscolar/BonoEscolarSlice';

export const BonoEscolarPage = () => {

    // ? HOOK-DISPATCH
    const dispatch = useDispatch();

    // ? HOOK-SELECTOR
    const listaBonoEscolarPeriodo = useSelector(selectBonoEscolarPeriodos);
    const isSuccess = useSelector(selectIsSuccess);
    const isLoading = useSelector(selectIsLoading);
    const message = useSelector(selectMessage);

    // ? HOOK-STATE
    const [bonoEscolarPeriodoEditar, setBonoEscolarPeriodoEditar] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [estadoModal, setEstadoModal] = useState(0);

    // ? HOOK NAVEGACION.
    const navigate = useNavigate();

    // * FUNCION PARA REFRESCAR LA LISTA DE BONO ESCOLAR.
    const refreshListBonoEscolar = () => dispatch(fetchBonoEscolarPeriodos());

    // * FUNCION PARA NAVEGACION.
    const NavigateTo = (ano) => {
        navigate(`/beneficios/bonoescolar/${ano}`);
    }

    // * FUNCION PARA ABRIR MODAL
    const OpenModal = () => {
        setEstadoModal(0);
        setOpenModal(true);
    }

    // * FUNCION PARA ABRIR MODAL
    const OpenModalEdit = (item) => {
        setBonoEscolarPeriodoEditar(item);
        setEstadoModal(1);
        setOpenModal(true);
    }

    // * FUNCION PARA ACTUALIZAR PERIODO.
    const updateBonificacionEscolarPeriodo = (ano) => {
        dispatch(fetchBonoEscolarPorPeriodoActualizarBeneficiarios(ano));
    }

    // * FUNCION PARA CERRAR UN PERIODO.
    const cerrarBonificacionEscolarPeriodo = (ano) => {
        swal({
            title: 'Confirmación',
            text: `Estás seguro/a que desea cerrar el período ${ano}?`,
            icon: 'warning',
            buttons: ['No', 'Si']
        }).then(respuesta => {
            if(respuesta){
                dispatch(fetchBonoEscolarCerrarPeriodo(ano));
            }
        });  
    }

    // ? HOOK-EFFECT
    useEffect(() => {
        refreshListBonoEscolar();
    }, [])

    useEffect(() => {
    
        if(isSuccess === 'success'){
          swal("Exitoso!", message, "success");
          refreshListBonoEscolar();
          dispatch(fetchCambiarSuccess());
        }
        else if(isSuccess === 'error'){
          swal("Error", "Por favor, comunicarse con el departamento de Desarrollo e Implentación de Sistemas.", "error");
          dispatch(fetchCambiarSuccess());
        }
    
    }, [isSuccess])
  

    // * VALIDACION ESTADO DEL ULTIMO PERIODO.
    const validationClosedPeriod = listaBonoEscolarPeriodo[listaBonoEscolarPeriodo?.length - 1]?.estado === 0 ? true : false;

  return (
    <Encabezado title='Bono Escolar'>

        <div className='d-flex justify-content-end'>
            <button disabled={validationClosedPeriod} className='btn btn-primary-mem' onClick={() => OpenModal()}>Agregar Bono Escolar <AddIcon sx={{marginTop: -0.5}} /></button>
        </div>
            
        <div className="row mt-3 mx-auto">     
        {
            listaBonoEscolarPeriodo.map((item, index) => (
                <div className="card mb-3" key={index}>
                    <div className="row g-0">
                        
                        <div className="col-2 text-center mt-3 mb-3 text-mem">
                            <p className="card-text ">BONO ESCOLAR</p>
                            <h6 className="fw-bold">{item.ano}</h6>
                        </div>

                        <div className="col-2">
                            <div className="text-center mt-3 mb-3">
                            <p className="card-text fw-bold text-14">Fecha Inicio</p>
                            <h6 className="text-14">{moment(item.fechaInicio).format('YYYY-MM-DD')}</h6>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="text-center mt-3 mb-3">
                            <p className="card-text fw-bold text-14">Fecha Término</p>
                            <h6 className="text-14">{moment(item.fechaTermino).format('YYYY-MM-DD')}</h6>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="text-center text-14 mt-3 mb-3">
                            <p className="card-text fw-bold">Cantidad Beneficiarios</p>
                            <h6 className="text-14">{item.cantidaddebeneficiarios}</h6>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="text-center mt-5 mb-3">
                            <h6 className="text-14 text-mem fw-bold" style={{textTransform: 'upperCase'}}>{item.estado === 1 ? 'Periodo Cerrado' : 'Período Abierto'}</h6>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className={item.estado === 0 ? 'mt-2 mb-2 d-flex justify-content-center' : 'mt-2 mb-2 d-flex justify-content-end'}>
                                
                                {
                                    item.estado !== 1 && 
                                    <>
                                    
                                        <IconButtonText title={""} Icon={ModeEditOutlineIcon} marginTopRequired = {true} func={OpenModalEdit} params={item} />
                                        <IconButtonText title={""} Icon={CachedIcon} marginTopRequired = {true} func={updateBonificacionEscolarPeriodo} params={item.ano}  />
                                        <IconButtonText title={""} Icon={DoneAllRoundedIcon} marginTopRequired = {true} func={cerrarBonificacionEscolarPeriodo} params={item.ano} color={'#005215'}  />  

                                    </>
                                }

                                <IconButtonText title={""} Icon={ExitToAppRoundedIcon} marginTopRequired = {true} func={NavigateTo} params={item.ano} />
                            </div>
                        </div>   

                    </div>
                </div>
            ))
        }

        <AgregarBonoEscolarModal 
        open={openModal}
        setOpen={setOpenModal}
        estadoAction={estadoModal}
        listaBonoEscolarPeriodo={listaBonoEscolarPeriodo}
        bonoEscolarPeriodoEdit={bonoEscolarPeriodoEditar}
        />

        {/* PANTALLA DE CARGA */}
        <Loading open={isLoading} />

    </div>
        
    </Encabezado>
  )
}
